// 懒加载路由
const Education = () => import('./Education.vue')

export default {
  path: '/Education',
  name: 'Education',
  component: Education,
  meta: {
    title: '阅读教育',
    authLogin: false //是否需要登录
  },
  children: []
}
