<template>
  <div class="Cooperation">
    <AppHeader :default-index="7"></AppHeader>
    <Banner class="banner" :list="contactUsBanner"></Banner>
    <div class="middle">
      <div class="middle-title">
        <span>合作伙伴</span>
      </div>
      <div class="middle-main">
        <ul class="main-ul">
          <li
            class="main-item"
            v-for="(item, index) in cooperatorListImg"
            :key="index"
          >
            <img :src="imageUrl + item.enclosure" />
          </li>
        </ul>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import { bannerDetail, cooperatorList } from '@/services/api.js'
export default {
  name: 'contactUs',
  components: {
    AppHeader,
    Banner,
    AppFooter,
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      contactUsBanner: [],
      cooperatorListImg: [],
      imageUrl: this.$http.imageUrl,
    }
  },
  computed: {},
  watch: {},
  created() {
    bannerDetail({
      type: '8',
    }).then((res_) => {
      this.contactUsBanner = res_.data
    })
    cooperatorList().then((res) => {
      console.log(res)
      this.cooperatorListImg = res.data
    })
  },
  mounted() {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner {
  position: relative;
  z-index: -1;
}
.middle {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(191, 191, 191, 0.5);
  margin: -170px auto 55px;
  padding: 60px;
  &-title {
    margin: 0 auto;
    width: 368px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #f52021;
    text-align: center;
    span {
      font-size: 22px;
      font-weight: 600;
      color: #222222;
      padding: 0 20px;
      background: #ffffff;
      position: relative;
      top: -20px;
    }
  }
  &-main {
    padding-top: 50px;
    .main-ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .main-item {
      flex-shrink: 0;
      margin: 0 20px 20px 0;
      img {
        width: 85px;
        height: 90px;
      }
    }
  }
}
</style>
