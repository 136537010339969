<template>
  <div class="title-item">
    <div class="title-item-inner " :class="{ center: center }">
      <div class="title-item-inner-bg">
        <div class="img">
          <img src="~images/compary/icon_33.png" alt="" />
        </div>
      </div>
      <div class="title-item-inner-con">
        <div class="title">
          {{ title }}
        </div>
        <div class="desc">
          {{ desc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleItem',
  props: {
    title: {
      type: String,
      default: '企业简介'
    },
    desc: {
      type: String,
      default: 'company profiles'
    },
    center: Boolean
  }
}
</script>

<style scoped lang="scss">
$hinght: 66px;
.title-item {
  width: 100%;
  height: $hinght;
  .title-item-inner {
    position: relative;
    .title-item-inner-bg {
      display: flex;
      justify-content: flex-start;
      .img {
        width: 95px;
        height: 66px;
      }
    }
    .title-item-inner-con {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      .title {
        font-size: $mo-font-size-xl;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 5px;
      }
      .desc {
        font-size: $mo-font-size-base;
        color: #666666;
        letter-spacing: 3px;
        text-transform: uppercase;
        line-height: 25px;
      }
    }
  }
  .center {
    text-align: center;
    .title-item-inner-bg {
      justify-content: center;
    }
  }
}
</style>
