import http from '@/utils/http'

// pc端轮播图
export function bannerDetail(data) {
  return http.get('app/pcBanner/list', data)
}

// pc端轮播图 1+x
export function bannerxList(data) {
  return http.get('app/pcBanner/xList', data)
}

// pc端咨询或新闻列表
export function pcNoticeList(data) {
  return http.get('app/notice/list', data, {showLoading:false})
}

// pc端新闻详细信息
export function noticeDetail(data) {
  return http.get('app/notice/detail', data)
}

// 商品分类
export function categoryList(data) {
  return http.get('goods/category/list', data, {showLoading:false})
}

// 商品列表
export function goodsList(data) {
  return http.get('goods/list', data, {showLoading:false})
}

// 热门图书
export function goodsHotList(data) {
  return http.get('goods/hotList', data)
}

// 招聘管理
export function jobList(data) {
  return http.get('app/job/list', data)
}

// 招聘管理详情
export function jobDetail(data) {
  return http.get('app/job/detail', data)
}

// 课程管理
export function dictList(data) {
  return http.get('app/dict/list', data, {showLoading:false})
}

// 课程详情
export function goodsDetail(data) {
  return http.get('goods/detail', data)
}

// 字典
export function dictGrade(data) {
  return http.get('app/dict/list', data)
}

// 公司网站_图书出版发行
export function publishedInfo(data) {
    return http.get('app/published/info', data)
}

// 公司网站_图书出版发行
export function booksAssemblyInfo(data) {
    return http.get('app/booksAssembly/info', data)
}

// 公司网站_家庭教育列表
export function familyEducationInfo(data) {
    return http.get('app/familyEducation/list', data)
}

// 公司网站_家庭教育详情
export function familyEducationDetail(data) {
    return http.get('app/familyEducation/detail', data)
}

// 公司网站_阅读教育列表
export function readEducationList(data) {
    return http.get('app/readEducation/list', data)
}

// 公司网站_阅读教育详情
export function readEducationDetail(data) {
    return http.get('app/readEducation/detail', data)
} 

// 公司网站_联系我们基础信息
export function contactUsInfoInfo(data) {
    return http.get('app/contactUsInfo/info', data)
}

// 公司网站_联系我们部门列表
export function contactUsInfoList(data) {
    return http.get('app/contactUsInfo/list', data)
}

// 公司网站_招募合伙人 合伙人报名须知
export function partnerBooksAssemblyInfo(data) {
    return http.get('app/booksAssembly/info', data)
}

// 公司网站_招募合伙人 合伙人报名须知
export function conditionInfo(data) {
    return http.get('app/partner/conditionInfo', data)
}

// 公司网站_招募合伙人 申请合伙人
export function apply(data) {
    return http.post('app/partner/apply', data)
}

// 公司网站_合作单位
export function cooperatorList(data) {
    return http.get('app/cooperator/list', data)
}

// 公司网站_图书租借
export function companyList(data) {
    return http.get('app/company/list', data)
}

// 企业发展历程-左
export function leftList(data) {
  return http.get('app/developmentHistory/leftList', data)
}

// 企业发展历程-右
export function rightList(data) {
  return http.get('app/developmentHistory/rightList', data)
}
