const joinUs = () => import('./joinUs.vue')
const Index = () => import('./Children/index.vue')
const details = () => import('./Children/detail.vue')
export default {
  path: '/joinUs',
  name: 'JoinUs',
  component: joinUs,
  meta: {
    title: '加入我们',
    authLogin: false //是否需要登录
  },
  children: [
    {
      path: '',
      name: 'index',
      component: Index,
      meta: {
        title: '加入我们',
        authLogin: false //是否需要登录
      }
    },
    {
      path: 'details',
      name: 'details',
      component: details,
      meta: {
        title: '职位详情',
        authLogin: false //是否需要登录
      }
    }
  ]
}
