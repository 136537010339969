<template>
  <div class="details">
    <div class="details-title">
      <span>职位详情</span>
    </div>
    <div class="details-inner container">
      <el-container>
        <el-aside width="240px">
          <Tabs :jobDataList="jobDataList" @active="activeTabs" :arrIndex="arrIndex"></Tabs>
        </el-aside>
        <el-main>
          <detailsItem :jobDetail="jobDetail"></detailsItem>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/content/joinUs/Tabs'
import detailsItem from '@/components/content/joinUs/detailsItem'
import { jobDetail, jobList } from '@/services/api.js'

export default {
  name: 'detail',
  components: {
    Tabs,
    detailsItem
  },
  data() {
    return {
      jobDetail: {},
      jobDataList: [],
      arrList: {},
      arrIndex: Number
    }
  },
  created() {
    this.arrIndex = parseInt(this.$route.query.index)
      jobDetail({
        id: this.$route.query.id
      }).then(res_ => {
        this.jobDetail = res_.data
      })
    jobList({
      pageNo: '1',
      pageSize: '100'
    }).then(res_ => {
      this.jobDataList = res_.data
    })
  },
  methods: {
    activeTabs(v) {
      this.arrList = v
      if(v.id){
        jobDetail({
          id: v.id
        }).then(res_ => {
          this.jobDetail = res_.data
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.details {
  .details-title {
    font-size: $mo-font-size-base;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    &:after,
    &:before {
      content: '';
      display: flex;
      flex-grow: 1;
      height: 1px;
      background: #e7e7eb;
    }

    span {
      margin: 0 10px;
    }
  }
}
</style>
