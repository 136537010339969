<template>
  <div class="joinUs">
    <AppHeader :default-index="6"></AppHeader>
    <Banner :list="joinUsBanner"></Banner>
    <router-view></router-view>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import { bannerDetail } from '@/services/api.js'

export default {
  name: 'joinUs',
  components: {
    AppHeader,
    Banner,
    AppFooter
  },
  mixins: [],
  props: {},
  data: function() {
    return {
      joinUsBanner: [],
      jobData: []
    }
  },
  computed: {},
  watch: {},
  created() {
    bannerDetail({
      type: '7'
    }).then(res_ => {
      this.joinUsBanner = res_.data
    })
  },
  mounted() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.joinUs {
}
</style>
