<template>
  <div class="Cooperation">
    <AppHeader :default-index="4" :isShadow="true"></AppHeader>
    <div class="middle">
      <h3 class="title">阅读基地实验校可视化</h3>
      <p class="desc">
        2016年基金会推出“全民阅读校园行”活动，先后在全省范围内建立了近四十家试点学校，这些学校既有乡镇学校和山区学校，又有县级和市级学校。在推广过程中，我们积累了丰富的经验，也沉淀了丰厚的教育教学资源，开展了形式多样的教研活动，取得了比较好的教学效果。
      </p>
      <div id="myChart" :style="{ width: '1200px', height: '597px' }"></div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import geoMap from '@/utils/geoMap.js'
import { companyList } from '@/services/api.js'
import image from '../../assets/images/code.jpg'
// const scatterData = [
//   {'value':["112.507445", "37.862172"]}
// ]

// var iconLJZ = "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPCAYAAAA/I0V3AAAA8ElEQVQokZWSMUoEQRBFX1WPqbCBgXoAcyPP4AnEG3gMAw9gYigY7RE8gYkHcBMzRZQVVNBg1K5vsN04DOPO+qGS5v9+/Oo2SVSFpQnw6sq/hwPyYj4OS8/APTALS9OwtPtnShIZP824etNm/EQS/TFJhKUpcDB0J3ALnAE3QAtcVdLFAKk/nxk/l7ToBGwvK170CBwBeFgy4Gkk8AHsuXIL0LiywtJmxzADrllsdgOYAIeu/FANTSHNgUtX3g9La678tQzr5SF3gDuAsUDt5MA78DJm7pIC2ALWVw6FpaaQ5v8hfZfA26qh+o1GN9bVDwGfiYLgZc5HAAAAAElFTkSuQmCC"
//监督人员(男): 图标

//监督人员(女): 图标

export default {
  name: 'Issue',
  components: {
    AppHeader,
    AppFooter,
  },
  data: function () {
    return {
      geoMap,
      journalistBanner: [],
      journaDetails: [],
      loading: true,
      scatterData: [
        // { 'value': [112.564781, 37.73647] },
        // { 'value': [112.563987, 37.737581] },
        // { 'value': [112.56742, 37.736673] },
        // { 'value': [112.564781, 37.711936] },
      ],
    }
  },
  computed: {},
  watch: {
    scatterData() {
      this.map(this.geoMap)
    },
  },
  created() {
    companyList().then((res) => {
      console.log('created', res), (this.companyList = res.data)
      this.companyList.forEach((item) => {
        if (item.longitude && item.latitude) {
          this.scatterData.push(this.fun(item))
        }
      })
    })
  },
  methods: {
    fun(arr) {
      let objValue = {
        address: '',
        companyName: '',
        value: [],
        image: image,
      }
      if (arr.longitude && arr.latitude) {
        objValue.value = [arr.longitude, arr.latitude]
        objValue.address = arr.address
        objValue.companyName = arr.companyName
      }
      return objValue
    },
    map(geoJson) {
      console.log('mounted', this.scatterData)
      // 基于准备好的dom，初始化echarts实例
      var echarts = require('echarts')
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('myChart'))
      var option = null
      echarts.registerMap('shanxi', geoJson)
      myChart.setOption(
        (option = {
          title: {
            text: '山西省',
            left: 'center',
          },
          geo: {
            map: 'shanxi',
            roam: false,
            zoom: 1.2,
            label: {
              normal: {
                show: true,
                areaColor: '#ffefd5',
                borderColor: '#111',
                textStyle: { color: '#222' },
              },
              emphasis: {
                show: true,
                textStyle: { color: '#fff' },
              },
            },
            itemStyle: {
              normal: {
                show: 'true',
                borderWidth: 2,
                borderColor: '#fff', //边框颜色
                areaColor: '#badcff', //地图区域颜色
              },
              emphasis: {
                show: false,
                areaColor: '#f47920',
              },
            },
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              tooltip: {
                trigger: 'item',
                confine: true,
                formatter: (p) => {
                  //自定义提示信息
                  let dataCon = p.data
                  let txtCon =
                    '场馆名称：' +
                    dataCon.companyName +
                    '<br />' +
                    '场馆地址：' +
                    dataCon.address
                  return txtCon
                },
              },
              type: 'scatter',
              coordinateSystem: 'geo',
              data: this.scatterData,
              symbolSize: 35, //描点的尺寸
              symbol: 'pin',
              // symbolSize: 12,
              label: {
                normal: {
                  show: false, //显示市区标签
                  textStyle: { color: '#c71585' }, //省份标签字体颜色
                },
                emphasis: {
                  //对应的鼠标悬浮效果
                  show: false, //关闭文字 （这东西有问题得关）
                  textStyle: { color: '#800080' },
                },
              },
              itemStyle: {
                normal: {
                  color: 'red', //标志颜色
                },
              },
            },
          ],
        })
      )
      option && myChart.setOption(option)
    },
    drawLine() {
      this.map(this.geoMap)
    },
  },
}
</script>

<style scoped lang="scss">
.middle {
  width: 1200px;
  margin: 60px auto;
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #222222;
    padding-left: 20px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -11px;
      width: 6px;
      height: 22px;
      background: #fd0002;
    }
  }
  .desc {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #4c4c4c;
    line-height: 30px;
  }
}
</style>