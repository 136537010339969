// 懒加载路由
const journalist = () => import('./journalist.vue')

export default {
  path: '/journalism',
  name: 'Journalist',
  component: journalist,
  meta: {
    title: '新闻列表',
    authLogin: false //是否需要登录
  },
  children: []
}
