<template>
  <div class="course">
    <AppHeader :default-index="4"></AppHeader>
    <Banner :list="corseBanner"></Banner>
    <DropDownList
      :dictList="dictList"
      @listData="gradeProp"
      @courseType="courseTypeProp"
      @teacher="teacherProp"
    ></DropDownList>
    <div class="list container">
      <el-row :gutter="40">
        <el-col :span="6" v-for="(v, i) in curriculum" :key="i">
          <CourseItem :item="v"></CourseItem>
        </el-col>
      </el-row>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :current-page.sync="current"
          :total="pagination.count"
          :page-size="8"
        >
        </el-pagination>
      </div>
    </div>
    <qrCode></qrCode>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import DropDownList from '@/components/content/course/DropDownList'
import CourseItem from '@/components/content/course/CourseItem'
import qrCode from '@/components/content/qrCode'
import { bannerDetail, dictList, goodsList } from '@/services/api.js'
export default {
  name: 'course',
  components: {
    AppHeader,
    Banner,
    AppFooter,
    DropDownList,
    CourseItem,
    qrCode
  },
  mixins: [],
  props: {},
  data: function() {
    return {
      pagination: {},
      current: 1,
      corseBanner: [],
      dictList: {},
      curriculum: [],
      // 页数
      pageNo: '1',
      // 年纪
      grade: '1',
      // 课程
      courseType: '1',
      // 老师
      teacher: '1'
    }
  },
  computed: {},
  created() {
    bannerDetail({
      type: '3'
    }).then(res_ => {
      this.corseBanner = res_.data
    })
    dictList().then(res_ => {
      this.dictList = res_.data
    })
    this.getList(this.pageNo, this.grade, this.courseType, this.teacher)
  },
  mounted() {},
  methods: {
    getList(pageNo, grade, courseType, teacher) {
      goodsList({
        pageNo: pageNo,
        pageSize: '10',
        property: '2',
        grade: grade,
        courseType: courseType,
        teacher: teacher
      }).then(res_ => {
        this.pagination = res_.data
        this.curriculum = res_.data.list
      })
    },
    gradeProp(gradeV) {
      this.current = 1
      this.grade = gradeV.value
      this.getList(this.pageNo, this.grade, this.courseType, this.teacher)
    },
    courseTypeProp(courseTypeV) {
      this.current = 1
      this.courseType = courseTypeV.value
      this.getList(this.pageNo, this.grade, this.courseType, this.teacher)
    },
    teacherProp(teacherV) {
      this.current = 1
      this.teacher = teacherV.value
      this.getList(this.pageNo, this.grade, this.courseType, this.teacher)
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList(this.pageNo, this.grade, this.courseType, this.teacher)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list {
  padding-bottom: 100px;
}
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
  ::v-deep.active {
    background: #f52021 !important;
  }
}
</style>
