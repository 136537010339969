<template>
  <div class="home">
    <AppHeader :default-index="0"></AppHeader>
    <Banner :list="HomeBanner"></Banner>
    <Block>
      <JournalismItem
        :noticeList="pcNoticeData"
        :Import="noticeImport"
      ></JournalismItem>
    </Block>
    <Block title="睿图公益阅读介绍" desc="Ruitu  Introduction">
      <OneXItem></OneXItem>
    </Block>
    <Block title="图书推荐" desc="Book Recommendation">
      <StoreItem :hotList="hotList"></StoreItem>
    </Block>
    <!-- <Block title="团队介绍" desc="Team Introduction">
      <TeamIntroducedItem></TeamIntroducedItem>
    </Block> -->
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import Block from '@/components/content/home/Block'
import JournalismItem from '@/components/content/home/JournalismItem'
import OneXItem from '@/components/content/home/OneXItem'
import StoreItem from '@/components/content/home/StoreItem'
// import TeamIntroducedItem from '@/components/content/home/TeamIntroducedItem'
import { bannerDetail, pcNoticeList, goodsHotList } from '@/services/api.js'

export default {
  name: 'Home',
  components: {
    AppHeader,
    Banner,
    AppFooter,
    Block,
    JournalismItem,
    OneXItem,
    StoreItem,
    // TeamIntroducedItem
  },
  mixins: [],
  props: {
    msg: {
      type: String,
      default: 'Home'
    }
  },
  data: function() {
    return {
      HomeBanner: [], // banner
      pcNoticeData: [], // 企业咨询
      noticeImport: {},
      hotList: []
    }
  },
  computed: {},
  watch: {},
  created() {
    bannerDetail({
      type: '0'
    }).then(res_ => {
      this.HomeBanner = res_.data
    })
    pcNoticeList({
      pageNo: '1',
      pageSize: '5'
      // noticeAtt:'1'
    }).then(res_ => {
      // 截取一个为重要
      let Import = res_.data.list.splice(0, 1)
      this.noticeImport = Import[0]
      this.pcNoticeData = res_.data.list.splice(0, 5)
    })
    // 图书推荐列表
    goodsHotList({
      pageNo: '1',
      pageSize: '10'
    }).then(res_ => {
      this.hotList = res_.data.list
    })
  },
  mounted() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
}
</style>
