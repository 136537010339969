const contactUs = () => import('./contactUs.vue')
export default {
  path: '/contactUs',
  name: 'ContactUs',
  component: contactUs,
  meta: {
    title: '联系我们',
    authLogin: false //是否需要登录
  },
  children: []
}
