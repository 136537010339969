<template>
  <div class="store-item">
      <div class="middle">
          <BooksItem v-for="(v, i) in hotList" :key="i" :item="v"></BooksItem>
      </div>
    <div class="btn">
      <el-button type="danger" plain @click="tobookStore()">
        了解更多
        <i class="el-icon-d-arrow-right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import { BooksItem } from 'content/store/book.js'
export default {
  props:{
    hotList:Array
  },
  components: {
    BooksItem
  },
  name: 'StoreItem',
  methods:{
    tobookStore(){
      this.$router.push({
        path: '/bookStore'
      })
    }
  }
}
</script>

<style scoped lang="scss">
div {
  /*outline: #4cd964 1px solid;*/
}
.store-item {
  background: #f5f7f9;
}
.btn {
  display: flex;
  justify-content: center;
  padding: $mo-spacing-row-base;
}
.middle {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
</style>
