<template>
  <div class="block">
    <div class="block-inner">
      <div class="title">
        <div class="title-inner">
          {{ title }}
        </div>
        <div class="title-desc">
          {{ desc }}
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '企业资讯'
    },
    desc: {
      type: String,
      default: 'Corporate News'
    }
  },
  name: 'Block'
}
</script>

<style scoped lang="scss">
.block {
  width: 100%;
  .block-inner {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    .title {
      width: 350px;
      height: 28px;
      border: 1px solid #c2c2c2;
      position: relative;
      display: flex;
      justify-content: center;
      .title-inner {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        padding: 0 40px;
        background: #fff;
        color: $mo-color-title;
        font-size: $mo-font-size-xl;
      }
      .title-desc {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        background: #fff;
        min-width: 200px;
        text-align: justify;
        text-align-last: justify;
        text-justify: inter-ideograph;
        letter-spacing: 10px;
        white-space: nowrap;
        color: $mo-color-subtitle;
        font-size: $mo-font-size-sm;
        text-transform: uppercase;
      }
    }
  }
}
</style>
