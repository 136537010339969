<template>
  <div class="Cooperation">
    <AppHeader :default-index="6"></AppHeader>
    <div class="main">
      <!-- <Banner class="banner" :list="contactUsBanner"></Banner> -->
      <div class="main-bg">
          <img src="../../assets/images/cooperation/icon_1.png" alt="">
      </div>
      <div class="cooperation-container">
        <div class="title">
          <p>— 合伙人招募 —</p>
          <p>Partner recruitment</p>
        </div>
        <div class="middle">
          <Introduce
            v-if="index == 0"
            :conditionData="conditionData"
            @IntroduceChange="IntroduceChange"
          ></Introduce>
          <PartnerForm
            v-else
            @PartnerFormChange="PartnerFormChange"
          ></PartnerForm>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Introduce from './childer/Introduce'
import PartnerForm from './childer/PartnerForm'
import { conditionInfo } from '@/services/api.js'

export default {
  name: 'contactUs',
  components: {
    AppHeader,
    AppFooter,
    Introduce,
    PartnerForm,
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      contactUsBanner: [ '' ],
      index: 0,
      conditionData: {}
    }
  },
  computed: {},
  watch: {},
  created() {
    //   合伙人须知
    conditionInfo().then( res => {
        console.log(res)
        this.conditionData = res.data
     })
  },
  mounted() {},
  methods: {
    IntroduceChange(e) {
      this.index = e
    },
    PartnerFormChange(e) {
      this.index = e
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner {
  position: relative;
  z-index: -1;
}
.main {
  position: relative;
  height: 100%;
  .main-bg {
    position: absolute;
    width: 100%;
    height: 80%;
    z-index: -1;
  }
  .cooperation-container {
    padding-top: 60px;
    width: 1200px;
    margin: 0 auto;
    // position: relative;
    // left: 50%;
    // margin-left: -600px;
    margin-bottom: 40px;

    .title {
      text-align: center;
      p {
        margin-bottom: 20px;
        &:first-child {
          font-size: 32px;
          font-weight: 600;
          color: #ffffff;
        }
        &:last-child {
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.8;
        }
      }
    }
    .middle {
    box-shadow: 0px 10px 30px 0px rgba(191, 191, 191, 0.5);
    min-height: 570px;
      margin-top: 20px;
      background: #fff;
      overflow: hidden;
    }
  }
}
</style>
