const oneX = () => import('./oneX.vue')
export default {
  path: '/oneX',
  name: 'OneX',
  component: oneX,
  meta: {
    title: '1+X计划',
    authLogin: false //是否需要登录
  },
  children: []
}
