<template>
  <div class="courseDetails">
    <AppHeader :default-index="4"></AppHeader>
    <div class="courseDetails-header">
      <div class="title">
        <div class="space">
          <span class="classes">课程导航</span>
          <span class="m-10 classes">></span>
          <span class="primary">{{ goodsData.name }}</span>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- 图片 -->
      <el-row :gutter="60">
        <el-col :span="11">
          <div class="img">
            <el-image
              :src="`${imageUrl + goodsData.mainImage}`"
              lazy
              fit="cover"
            ></el-image>
          </div>
        </el-col>
        <!-- 课程 -->
        <el-col :span="11">
          <div class="introduction">
            <span class="curriculum">{{ goodsData.name }}</span>
            <span class="sp ter">教师：{{ goodsData.teacher }}</span>
            <span class="sp ter">课程：{{ goodsData.courseType }}</span>
            <span class="sp stu">
              年级：<span class="classes">{{ goodsData.grade }}</span>
            </span>
            <span class="sp">小节数：{{ goodsData.pitchNumber }}节</span>
            <div class="sp price pointer">
              <el-image
                :src="require('images/course/icon57.png')"
                lazy
                fit="cover"
              ></el-image>
              <div class="pavas">
                <span class="priceIcon">￥</span>
                <span class="num">{{ goodsData.sellPrice }}</span>
                <span class="deleprice">￥{{ goodsData.bazaarPrice }}</span>
              </div>
              <div class="popup-code">
                <img src="../../assets/images/ql.png" alt="" />
                <p>扫一扫</p>
                <p>至<span>千聊</span>中查看</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="details">
        <span>课程详情</span>
      </div>
    </div>
    <div class="main p-20">
      <div class="dataContent" v-html="goodsData.content"></div>
    </div>
    <qrCode></qrCode>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import qrCode from '@/components/content/qrCode'
import { goodsDetail } from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    qrCode,
    AppFooter,
  },
  data() {
    return {
      goodsData: {},
      imageUrl: '',
    }
  },
  name: 'CourseDetails',
  created() {
    this.imageUrl = config.imageUrl
    goodsDetail({
      goodsId: this.$route.query.id,
    }).then((res_) => {
      this.goodsData = res_.data.goods
    })
  },
}
</script>

<style scoped lang="scss">
.courseDetails {
  &-header {
    height: 60px;
    width: 100%;
    background: #eef5ff;
    .title {
      width: 1200px;
      margin: 0 auto;
      .space {
        position: relative;
        .classes {
          color: #222222;
          font-size: 18px;
          line-height: 60px;
          margin-left: 20px;
        }
        .primary {
          color: #294bfe;
          font-size: 18px;
          line-height: 60px;
          margin-left: 15px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -11px;
          width: 8px;
          height: 22px;
          background: #294bfe;
          display: inline-block;
        }
        .m-10 {
          margin-left: 15px;
        }
      }
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    .img {
      height: 290px;
      overflow: hidden;
      width: 100%;
      border-radius: 30px;
      .el-image {
        height: 100%;
        width: 100%;
        transition: all 500ms ease;
      }
    }
    .introduction {
      display: flex;
      flex-direction: column;
      .curriculum {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
      }
      .sp {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        .classes {
          padding: 5px;
          border: 1px solid #294bfe;
          border-radius: 5px;
          color: #294bfe;
          font-size: 14px;
        }
      }
      .price {
        background: linear-gradient(90deg, #3b76fe 0%, #4d9dff 100%);
        width: 100%;
        height: 66px;
        line-height: 66px;
        position: relative;
        .pavas {
          position: absolute;
          top: 0;
        }
        .priceIcon {
          color: #ffffff;
          margin-left: 30px;
        }
        .deleprice {
          margin-left: 20px;
          font-size: 18px;
          line-height: 66px;
          color: #ffffff;
          text-decoration: line-through;
        }
        .num {
          color: #ffffff;
          font-size: 28px;
        }
        &:hover {
            .popup-code {
                display: block;
            }
        }
      }
    }
    .dataContent {
      font-size: 16px;
      color: #666666;
      line-height: 1.8;
    }
  }
  .details {
    width: 1200px;
    margin: 0 auto;
    span {
      margin-top: 40px;
      border: 1px solid #294bfe;
      padding: 10px 20px;
      display: inline-block;
      border-radius: 4px;
      color: #294bfe;
    }
  }
  .p-20 {
    padding-top: 0;
  }
  .popup-code {
    display: none;
    position: absolute;
    top: -30px;
    right: -170px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(204, 204, 204, 0.6);
    border-radius: 4px;
    img {
      width: 124px;
      height: 124px;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: #222222;
      line-height: 30px;
      span {
        color: #1e58ff;
      }
    }
  }
}
</style>
