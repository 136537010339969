<template>
  <div class="information-item">
    <div class="img">
      <el-image
        :src="`${imageUrl + item.titleImage}`"
        fit="cover"
      ></el-image>
    </div>
    <div class="right" @click="readPassage(item.id)">
      <div class="right-inner">
        <div class="title-inner">
          <div class="title">
            {{item.title}}
          </div>
          <div class="date">
            {{item.noticeTime}}
          </div>
        </div>
        <div class="desc e-3">
          {{item.content | contentInfo }}
          <!-- 2020年8月26日，省委宣传部专门召开颁奖会议，山西日报、山西电视台等十多家省内的外新闻媒体给予报道。会上，省委常委、宣传部长吕岩松同志给项目负责人薛张华同志颁发了证书和奖牌，并号召相关部门对此项目予以支持并把这项公益活动在山西开展的更加扎实。 -->
        </div>
        <div class="btn">
          <el-button size="small" type="danger" @click.stop="readPassage(item.id)">阅读全文</el-button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import config from '@/utils/config'

export default {
  props: {
    item: Object,
  },
  name: 'InformationItem',
  data() {
    return {
      imageUrl:''
    }
  },
  created() {
    this.imageUrl = config.imageUrl
  },
  methods:{
    readPassage(id){
      this.$router.push({
        name: 'JournalismDetails',
        query: { id : id}
      })
    }
  },
  filters:{
    contentInfo(val) {
      let regx = /<[^>]*>|<\/[^>]*>/gm
      let valRegx1 = val.replace(regx, '')
      let valRegx2 = valRegx1.replace(/&rdquo;/g, '')
      let valRegx3 = valRegx2.replace(/&ldquo;/g, '')
      let valRegx4 = valRegx3.replace(/&mdash;/g, '')
      let valRegx5 = valRegx4.replace(/&middot;/g, '')
      let valRegx6 = valRegx5.replace(/&nbsp;/g, '')
      let valRegx7 = valRegx6.replace(/&hellip;/g, '')
      let valRegx8 = valRegx7.replace(/&lsquo;/g, '')
      return valRegx8
    }
  }
}
</script>

<style scoped lang="scss">
.information-item {
  display: flex;
  margin-top: 20px;
  .img {
    width: 312px;
    height: 188px;
    flex-shrink: 0;
    ::v-deep.el-image {
      height: 100%;
      width: 100%;
    }
  }
  .right {
    border-bottom: 1px #999 dotted;
    padding-left: 20px;
    cursor: pointer;
    flex: 1;
    .right-inner {
      .title-inner {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: $mo-font-size-xl;
          font-weight: 600;
          line-height: 40px;
        }
        .date {
          color: #999;
        }
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 35px;
      }
      .btn {
        text-align: right;
      }
    }
  }

}
</style>
