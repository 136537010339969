<template>
  <el-row>
    <el-col :span="12" class="b-r">
      <div class="p-d30">
        <div class="desc-title">
          <span>志愿者招募条件</span>
        </div>
        <div class="desc" v-html="conditionData.conditions">
        </div>
      </div>
      <div class="p-d30 b-t">
        <div class="desc-title">
          <span>志愿者工作范围</span>
        </div>
        <div class="desc" v-html="conditionData.scope" >
        </div>
      </div>
    </el-col>
    <el-col :span="12" class="p-d30">
      <div class="desc-title">
        <span>志愿者待遇</span>
      </div>
      <div class="desc" v-html="conditionData.treatment">
      </div>
    </el-col>
    <el-col :span="24">
      <div class="btn" @click="clickBtn">
        <span>申请成为合伙人</span>
        <i class="el-icon-right"></i>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    conditionData: Object,
  },
  // 定义属性
  data() {
    return {}
  },

  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    clickBtn() {
      this.$emit('IntroduceChange', 1)
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='scss' scoped>
.desc-title {
  span {
    font-size: 16px;
    font-weight: 600;
    color: #222222;
    padding-left: 14px;
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 0;
      width: 6px;
      height: 17px;
      background: #ca2222;
    }
  }
}
.desc {
  margin: 20px 0;
  p {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #3c3c3c;
    line-height: 32px;
  }
  .p-l {
    padding-left: 40px;
  }
}
.btn {
  cursor: pointer;
  display: inline-block;
  padding: 10px 60px;
  background: #f52021;
  position: relative;
  left: 50%;
  margin-left: -10%;
  margin-bottom: 50px;
  span {
    font-size: 16px;
    color: #ffffff;
  }
  i {
    font-size: 16px;
    color: #ffffff;
  }
}
.p-d30 {
  padding: 30px;
}
.b-r {
  border-right: 1px solid #fff;
}
.m-t {
  margin-top: -110px;
}
.p-d {
  padding: 0 30px;
}
.b-t {
  border-top: 1px solid #fff;
}
.p-t {
  padding-top: 30px;
}
</style>