<template>
  <div class="courseDetails">
    <AppHeader :default-index="5"></AppHeader>
    <div class="courseDetails-header">
      <div class="title">
        <div class="space">
          <span class="classes">图书详情</span>
          <span class="m-10 classes">></span>
          <span class="primary">{{ goodsData.name }}</span>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- 图片 -->
      <el-row :gutter="60">
        <el-col :span="6">
          <div class="img">
            <el-image
              :src="`${imageUrl + goodsData.mainImage}`"
              lazy
              fit="cover"
            ></el-image>
          </div>
        </el-col>
        <!-- 课程 -->
        <el-col :span="18">
          <div class="introduction">
            <span class="curriculum">{{ goodsData.name }}</span>
            <span class="sp ter">{{ goodsData.remarks || '' }}</span>
            <!-- <span class="sp"><img src="../../assets/images/bookStore/icon_72.png" alt=""> 王小波 著</span> -->
            <span class="sp"
              ><img src="../../assets/images/bookStore/icon_74.png" alt="" />
              浏览人数：{{ goodsData.scanNum }}</span
            >
            <span class="sp"
              ><img src="../../assets/images/bookStore/icon_73.png" alt="" />
              适合年龄段：<span class="age">{{
                goodsData.goodsCategory && goodsData.goodsCategory.name
              }}</span></span
            >
            <div class="sp price">
              <el-image
                :src="require('images/course/icon57.png')"
                lazy
                fit="cover"
                class="pointer pointer-hover"
              ></el-image>
              <div class="pavas">
                <span class="priceIcon">￥</span>
                <span class="num">{{ goodsData.sellPrice }}</span>
                <span class="deleprice">￥{{ goodsData.bazaarPrice }}</span>
              </div>
              <div class="vcode">
                <img style="width:124px;height:124px" src="~images/code.jpg" />
                <p class="wx-code">微信扫一扫</p>
                <p class="wx-code">至<span>图书小程序</span>内购买</p>
              </div>
              <div class="vcode right">
                <img style="width:124px;height:124px" src="~images/wxxd_code.png" />
                <p class="wx-code">微信扫一扫</p>
                <p class="wx-code">至<span>微信小店</span>内购买</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="details">
        <span>图书介绍</span>
      </div>
    </div>
    <div class="main p-20">
      <div class="dataContent" v-html="goodsData.content"></div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import { goodsDetail } from '@/services/api.js'
import config from '@/utils/config'

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      goodsData: {},
      imageUrl: '',
    }
  },
  name: 'bookStoreDetails',
  created() {
    this.imageUrl = config.imageUrl
    goodsDetail({
      goodsId: this.$route.query.id,
    }).then((res_) => {
      this.goodsData = res_.data.goods
    })
  },
}
</script>

<style scoped lang="scss">
.courseDetails {
  &-header {
    height: 60px;
    width: 100%;
    background: #eef5ff;
    .title {
      width: 1200px;
      margin: 0 auto;
      .space {
        position: relative;
        .classes {
          color: #222222;
          font-size: 18px;
          line-height: 60px;
          margin-left: 20px;
        }
        .primary {
          color: #f52021;
          font-size: 18px;
          line-height: 60px;
          margin-left: 10px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -11px;
          width: 8px;
          height: 22px;
          background: #f52021;
          display: inline-block;
        }
        .m-10 {
          margin-left: 15px;
        }
      }
    }
  }
  .main {
    min-height: 200px;
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    .img {
      height: 290px;
      overflow: hidden;
      width: 100%;
      .el-image {
        height: 100%;
        width: 100%;
        transition: all 500ms ease;
      }
    }
    .introduction {
      display: flex;
      flex-direction: column;
      .curriculum {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
      }

      .sp {
        font-size: 16px;
        font-weight: 400;
        color: #222;
        margin-top: 20px;
        img {
          width: 15px;
          height: 13px;
          margin: 0 5px;
        }
        .age {
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #f52021;
          color: #f52021;
        }
        .classes {
          padding: 5px;
          border: 1px solid #294bfe;
          border-radius: 5px;
          color: #294bfe;
          font-size: 14px;
        }
      }
      .ter {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 1.8;
      }
      .price {
        background: linear-gradient(90deg, #f52021 0%, #ff6d40 100%);
        width: 516px;
        height: 66px;
        line-height: 66px;
        position: relative;
        .pavas {
          position: absolute;
          top: 0;
        }
        .priceIcon {
          color: #ffffff;
          margin-left: 30px;
        }
        .deleprice {
          margin-left: 20px;
          font-size: 18px;
          line-height: 66px;
          color: #ffffff;
          text-decoration: line-through;
        }
        .num {
          color: #ffffff;
          font-size: 28px;
        }
        &:hover {
          .vcode {
            display: block;
          }
        }
      }
    }
    .dataContent {
      font-size: 16px;
      color: #666666;
      line-height: 1.8;
    }
  }
  .details {
    width: 1200px;
    margin: 0 auto;
    span {
      margin-top: 40px;
      border: 1px solid #f52021;
      padding: 10px 20px;
      display: inline-block;
      border-radius: 4px;
      color: #f52021;
    }
  }
  .p-20 {
    padding-top: 0;
  }
  .vcode {
    display: none;
    position: absolute;
    top: -50px;
    right: -180px;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(204, 204, 204, 0.6);
    border-radius: 4px;
    .wx-code {
      font-size: 14px;
      font-weight: 600;
      color: #222222;
      line-height: 30px;
      span {
        color: #1e58ff;
      }
    }
  }
  .right {
    position: absolute;
    top: -50px;
    right: -360px;
  }
}
</style>
