const bookStore = () => import('./Assembly.vue')
export default {
  path: '/Assembly',
  name: 'Assembly',
  component: bookStore,
  meta: {
    title: '图书装配',
    authLogin: false //是否需要登录
  },
  children: []
}
