<template>
  <div class="journalism-item container pointer">
    <div
      class="journalism-item-inner"
      @click="skip(detailItem.id)"
      v-for="(detailItem, index) in detailList"
      :key="index"
    >
      <div class="left">
        <div class="title">
          {{ detailItem.title }}
        </div>
        <div class="desc">
          {{ detailItem.content | contentRegx }}
        </div>
        <el-button type="danger" plain style="border: none" size="small">
          阅读全文
        </el-button>

        <div class="date" v-if="detailItem.publishTime">
          <span>{{ detailItem.publishTime | date }}</span>
          <span>{{ detailItem.publishTime | specificDate }}</span>
        </div>
        <div class="date" v-else>
          <span>{{ detailItem.noticeTime | date }}</span>
          <span>{{ detailItem.noticeTime | specificDate }}</span>
        </div>
      </div>
      <div class="img" v-if="detailItem.titleImage">
        <el-image
          :src="`${imageUrl + detailItem.titleImage}`"
          fit="cover"
          lazy
          style="width: 100%; height: 100%"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailList: {
      type: Array,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    isFamily: Number,
  },
  name: 'JournalismItem',
  data() {
    return {
      imageUrl: this.$http.imageUrl,
    }
  },
  methods: {
    skip(id) {
      if (!this.isLink) {
        let routerPuth = this.$router.resolve({
          name: 'JournalismDetails',
          query: { id: id },
        })
        window.open(routerPuth.href, '_blank')
      } else {
        let type = 'family'
        if (this.isFamily == 1) {
          type = 'education'
        }
        let routerPuth = this.$router.resolve({
          name: 'ContactUsDetails',
          query: { id: id, type: type },
        })
        window.open(routerPuth.href, '_blank')
      }
    },
  },
  filters: {
    date(value) {
      return value.substring(value.length - 2)
    },
    specificDate(value) {
      return value.substring(0, value.length - 3)
    },
    contentRegx(val) {
      let regx = /<[^>]*>|<\/[^>]*>/gm
      let valRegx1 = val.replace(regx, '')
      let valRegx2 = valRegx1.replace(/&rdquo;/g, '')
      let valRegx3 = valRegx2.replace(/&ldquo;/g, '')
      let valRegx4 = valRegx3.replace(/&mdash;/g, '')
      let valRegx5 = valRegx4.replace(/&middot;/g, '')
      let valRegx6 = valRegx5.replace(/&nbsp;/g, '')
      let valRegx7 = valRegx6.replace(/&hellip;/g, '')
      let valRegx8 = valRegx7.replace(/&lsquo;/g, '')
      return valRegx8
    },
  },
}
</script>

<style scoped lang="scss">
div {
  //outline: 1px solid #4cd964;
}

.journalism-item-inner {
  display: flex;
  &:hover {
    color: $mo-main-color;
    .desc {
      color: $mo-main-color !important;
    }
  }
  .img {
    flex-shrink: 0;
    width: 312px;
    height: 188px;
  }
  .left {
    padding-left: 50px;
    border-top: 1px #535254 dotted;
    border-left: 1px #535254 dotted;
    margin-right: $mo-spacing-row-base;
    position: relative;
    height: 234px;
    flex: 1;


    .title {
      font-size: $mo-font-size-lg;
      font-weight: 600;
      line-height: 50px;
    }
    .desc {
      color: #333333;
      font-size: $mo-font-size-base;
      line-height: 37px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .el-button {
      position: absolute;
      right: 0px;
      bottom: 20px;
    }
    .date {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      span:first-child {
        font-size: 30px;
        font-family: DIN;
        font-weight: bold;
        color: #222222;
      }
      span:last-child {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #222222;
      }
    }
  }
}
</style>
