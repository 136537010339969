// 懒加载路由
const HomeEducation = () => import('./HomeEducation.vue')

export default {
  path: '/HomeEducation',
  name: 'HomeEducation',
  component: HomeEducation,
  meta: {
    title: '家庭教育',
    authLogin: false //是否需要登录
  },
  children: []
}
