<template>
  <div class="IntroductionItem">
    <div class="IntroductionItem-inner">
      <div class="left">
        <div class="title">
          此“公益阅读”项目包含部分:
        </div>
        <div class="tabs"
          v-for="(item,index) in xImg"
          :key="index"
        >
          <div class="tabs-item">
            <div @click="clickTabs(index,item)" :class="['tabsitemBlock',cunnerIndex == index ? 'active' : '']">
                <div class="tabs-item-inner">
                  <span v-if="index < 10">{{`0${index+1}`}}</span>
                  <span v-else>{{`${index+1}`}}</span>
                  <span>{{ item.name }}</span>
                  <i class="el-icon-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <div class="desc e-4">
            {{xPublicWelfare.content | contentWeflae}}
            <!-- 改造我校阅览室，配备海量适合小学生各个年级阅读的图书，供学生免费阅读。图书题材涵盖天文地理、人文历史、科技军事、绘本哲学等。以我们学校4000人为例，按1:3来配备图书，每学期可供学生阅读的图书有12000册。配备的图书每学期更换一次，一年会有20000多册优质图书供学生免费阅读。改造我校阅览室，配备海量适合小学生各个年级阅读的图书，供学生免费阅读。图书题材涵盖天文地理、人文历史、科技军事、绘本哲学等。以我们学校4000人为例，按1:3来配备图书，每学期可供学生阅读的图书有12000册。配备的图书每学期更换一次，一年会有20000多册优质图书供学生免费阅读。 -->
          </div>
          <div class="num" v-if="index < 10">
            {{`0${index+1}`}}
          </div>
          <div class="num" v-else>
            {{`0${index+1}`}}
          </div>
        </div>
        <div class="imgList">
          <div class="imgList-inner">
            <div class="imfList-inner-" ref="imgList">
              <div class="img" v-for="(item,index) in image" :key="index">
                <el-image
                  :src="item"
                  fit="cover"
                  :preview-src-list="image"
                ></el-image>
              </div>
            </div>
            <div class="block">
              <el-slider v-model="slideRvalue" :show-tooltip="false">
              </el-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/utils/config'

export default {
  props:{
    xWelfare: Array,
    xImg:Array
  },
  name: 'IntroductionItem',
  data() {
    return {
      slideRvalue: 0,
      Imglist: [],
      xPublicWelfare:{},
      imageUrl: '',
      cunnerIndex: 0,
      index: 1,
      image:[],
    }
  },
  created() {
    this.imageUrl = config.imageUrl
  },
  methods:{
    clickTabs(index,item){
      this.image = []
      this.xPublicWelfare = item
      this.cunnerIndex = index
      this.index = index
      let img = this.xImg[this.cunnerIndex].image.split(',')
      img.filter( item => {
        this.image.push(`${this.imageUrl + item}`)
      })
      console.log(this.image.length)
      if(this.image.length <= 2){
        this.slideRvalue = 100
      }else{
        this.slideRvalue = 0
      }
    }
  },
  watch: {
    slideRvalue() {
      let w = (200 * this.image.length) / 100
      if(this.image.length <= 2){
        this.$refs.imgList.style = `margin-left:-${0}px;`
      }else{
        this.$refs.imgList.style = `margin-left:-${w * this.slideRvalue}px;`
      }
    },
    xImg(){
      this.clickTabs(this.cunnerIndex,this.xImg[this.cunnerIndex])
    }
  },
  filters: {
    contentWeflae(val) {
      if(val){
        let regx = /<[^>]*>|<\/[^>]*>/gm;
        let valRegx1 = val.replace(regx,"");
        let valRegx2 = valRegx1.replace(/&rdquo/g,"" )
        let valRegx3 = valRegx2.replace(/&ldquo/g,"")
        let valRegx4 = valRegx3.replace(/&mdash/g,"")
	      return valRegx4
      }
    }
  }
}
</script>

<style scoped lang="scss">
.imgList {
  width: 100%;
  display: flex;
  flex-direction: column;

  .imgList-inner {
    overflow-y: auto;
    width: 600px;

    &::-webkit-scrollbar {
      display: none;
    }

    .imfList-inner- {
      display: flex;
      flex-wrap: nowrap;
    }

    .img {
      flex-shrink: 0;
      width: 240px;
      height: 138px;
      overflow: hidden;
      border-radius: 12px;
      margin: 10px;

      ::v-deep.el-image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .block {
    width: 80%;
    margin: 0 auto;
  }
}

.IntroductionItem {
  width: 100%;
  height: 420px;
  min-width: $mo-main-width-base;

  .IntroductionItem-inner {
    position: absolute;
    height: 420px;
    right: 0;
    display: flex;
    min-width: $mo-main-width-base;
    margin-left: calc(50% - 600px);

    .left {
      width: 40%;
      flex-shrink: 0;
      height: 100%;
      min-width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;

      .title {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #888888;
        line-height: 40px;
      }

      .tabs {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .tabsitemBlock {
          cursor: pointer;
          margin-top: 40px;
          line-height: 20px;
          border-radius: 4px;
          border: 1px solid #3d7ae4;
          padding: 15px;
          white-space: nowrap;
          display: inline-block;

          .tabs-item-inner {
            display: flex;
            align-items: center;
          }

          i {
            width: 24px;
            height: 24px;
            background: #ffffff;
            border-radius: 50%;
            line-height: 24px;
            text-align: center;
            transition: all 1s;
          }

          span:first-child {
            margin-right: 8px;
          }
        }
      }
    }

    .right {
      background: rgb(240, 246, 255);
      flex-grow: 0;
      display: flex;
      flex-direction: column;

      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 35px 15px;

        .desc {
          width: 60%;
          font-size: 14px;
          font-weight: 400;
          color: #4c4c4c;
          line-height: 35px;
        }

        .num {
          font-size: 100px;
          font-weight: 600;
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgb(212, 225, 250);
        }
      }
    }
  }
  .active {
    background: #3d7ae4;
    box-shadow: 0px 10px 30px 0px rgba(147, 186, 255, 0.6);
    color: #fff;
    i {
      color: #007aff;
      transform: translateX(10px);
    }
  }
}
</style>
