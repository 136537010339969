<template>
  <div class="bookStore">
    <AppHeader :default-index="5"></AppHeader>
    <Banner :list="bookBanner"></Banner>
    <BookList v-loading="loading" element-loading-text="加载中"  line :categoryList="parentList" :bookList="bookCategoryList" @change="changeTabs" :length="goodsData.length">
      <BooksItem v-for="(v, i) in goodsData" :key="i" :item="v"></BooksItem>
    </BookList>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :current-page.sync="current"
        :total="arrSize.count"
        :page-size="10"
      >
      </el-pagination>
    </div>
    <!-- <div style="height: 100px"></div> -->
    <qrCode></qrCode>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Banner from '@/components/content/Banner'
import { AppHeader, AppFooter } from 'content/App/app'
import { BookList, BooksItem } from 'content/store/book.js'
import qrCode from '@/components/content/qrCode'
import { bannerDetail, goodsList, dictGrade, categoryList } from '@/services/api.js'
export default {
  name: 'bookStore',
  components: {
    AppHeader,
    AppFooter,
    Banner,
    BookList,
    BooksItem,
    qrCode
  },
  mixins: [],
  props: {},
  data: function() {
    return {
      bookBanner: [],
      parentList: [],
      bookCategoryList:[],
      goodsData: [],
      arrSize: 0,
      pageCount: '0',
      cunnerIndex: 0,
      pageNo: 1,
      current: 1,
      goods: {},
      loading: true
    }
  },
  computed: {},
  watch: {},
  created() {
    bannerDetail({
      type: '5'
    }).then(res_ => {
      this.bookBanner = res_.data
    })
    // 年龄分类列表
    dictGrade().then(res_ =>{
      this.parentList = res_.data.readAge
      console.log(this.parentList )
    })
    // 图书分类列表
    categoryList().then(res_ => {
        this.bookCategoryList = res_.data.parentList
        // console.log(res_    )
    })
  },
  mounted() {},
  methods: {
    changeTabs(v) {
      this.goods = v
      this.current = 1
      goodsList({
        pageNo: this.pageNo,
        pageSize: '10',
        readAge: v.value,
        'goodsCategory.id': v.id,
        property: '1'
      }).then(res_ => {
        if(res_.code == 200){
          this.arrSize = res_.data
          this.goodsData = res_.data.list
          this.loading = false
        }
      })
    },
    handleCurrentChange(val) {
      goodsList({
        pageNo: val,
        pageSize: '10',
        property: '1',
        readAge: this.goods.value
      }).then(res_ => {
        this.arrSize = res_.data
        this.goodsData = res_.data.list
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bookStore {
  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px;
    ::v-deep.active {
      background: #f52021 !important;
    }
  }
}
</style>
