const oneX = () => import('./Partner.vue')
export default {
  path: '/Partner',
  name: 'Partner',
  component: oneX,
  meta: {
    title: '招募合伙人',
    authLogin: false //是否需要登录
  },
  children: []
}
