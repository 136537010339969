import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 2.获取路由信息
// 动态加载views中所有的路由文件
const files = require.context('@/views', true, /router\.js$/)
const routes = files.keys().map(key => {
  const page = require('@/views' + key.replace('.', ''))
  return page.default
})

process.env.NODE_ENV === 'development' ? console.log(routes, 'routes') : ''
// 3.创建路由对象
const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || to.name
  if (to.matched.some(record => record.meta.authLogin)) {
    if (store.state.token) {
      next()
    } else {
      next('login')
    }
  } else {
    next()
  }
})
// process.env.NODE_ENV === 'development' ? console.log(router, 'router') : ''

export default router

// const About = () => import('./login.vue')
// export default {
//   path: '/About',
//   name: 'About',
//   component: About,
//   meta: {
//     title: '关于我们',
//     authLogin: false,//是否需要登录
//   },
//   children: [
//   ]
// }
