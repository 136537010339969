<template>
  <div class="course-item">
    <div class="course-item-inner" @click="toCourse(item.id)">
      <div class="img">
        <el-image
          :src="`${imageUrl + item.mainImage}`"
          lazy
          fit="cover"
        ></el-image>
      </div>
      <div class="title">
        {{item.name}}
      </div>
      <div class="desc">
        {{item.teacher}} | 共{{item.pitchNumber}}节
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/utils/config'

export default {
  props: {
    item: Object
  },
  name: 'CourseItem',
  data() {
    return {
      imageUrl: ''
    }
  },
  created() {
    this.imageUrl = config.imageUrl
  },
  methods:{
    toCourse(id){
      this.$router.push({
        name:'CourseDetails',
        query: {id : id}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.course-item {
  padding-bottom: $mo-spacing-col-lg;
}
.course-item-inner {
  .img {
    height: 190px;
    border-radius: $mo-border-radius-lg;
    overflow: hidden;
    .el-image {
      height: 100%;
      transition: all 500ms ease;
    }
  }
  .title {
    font-size: $mo-font-size-lg;
    margin-top: $mo-spacing-row-sm;
    font-weight: 600;
    line-height: 30px;
  }
  .desc {
    font-size: $mo-font-size-base;
    color: $mo-text-color-grey;
  }
  &:hover {
    .el-image {
      transform: scale(1.1);
    }
    color: #007aff;
    .desc {
      color: #007aff;
    }
  }
}
</style>
