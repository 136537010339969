}
<template>
  <div class="banner">
    <el-carousel height="500px" indicator-position="none">
      <el-carousel-item v-for="(item, i) in list" :key="i">
        <div class="banner-inner">
          <el-image
            :src="`${imageUrl + item.image}`"
            fit="fill"
          ></el-image>
          <div class="index">
            {{ `0${i + 1}` }}
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
// import banner from 'assets/images/home/icon_20.png'
import config from '@/utils/config'

export default {
  props: {
    list: {
      type: Array,
      default: () => [
        {
          img: ''
        },
        {
          img: ''
        },
        {
          img: ''
        }
      ]
    }
  },
  name: 'Banner',
  data() {
    return {
      imageUrl:''
    }
  },
  created(){
    this.imageUrl = config.imageUrl
  }
}
</script>

<style scoped lang="scss">
.banner {
  min-width: $mo-main-width-base;
}
.banner-inner {
  width: 100%;
  height: 500px;
  position: relative;

  .el-image {
    width: 100%;
    height: 100%;
  }
  .index {
    position: absolute;
    font-size: 22px;
    font-family: DIN;
    font-weight: bold;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: #999999;
    }
  }
}
</style>
