<template>
  <div class="contactUs">
    <AppHeader :default-index="6"></AppHeader>
    <Banner :list="contactUsBanner"></Banner>
    <Block title="联系我们" desc="contact us">
      <div class="block-contactUs container">
        <div class="img">
          <img :src="imagUrl + info.logo" alt="" />
        </div>
        <div class="left">
          <div class="left-inner">
            <div class="item">
              <i class="el-icon-location-outline"></i>
              <span>公司地址：</span>
              <span>{{ info.address }}</span>
            </div>
            <div class="item">
              <i class="el-icon-phone"></i>
              <span>联系方式：</span>
              <span>{{ info.phone }}</span>
            </div>
            <div class="item">
              <i class="el-icon-message"></i>
              <span>公司邮箱：</span>
              <span>{{ info.email }}</span>
            </div>
          </div>
          <div class="grild">
            <div
              class="grild-item pointer"
              v-for="(item, index) in telData"
              :key="index"
            >
              <p>{{ item.name }}</p>
              <p>{{ item.phone }}</p>
            </div>
          </div>
        </div>
      </div>
    </Block>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import Block from '@/components/content/home/Block'
import {
  bannerDetail,
  contactUsInfoInfo,
  contactUsInfoList,
} from '@/services/api.js'
export default {
  name: 'contactUs',
  components: {
    AppHeader,
    Banner,
    AppFooter,
    Block,
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      contactUsBanner: [],
      telData: [],
      info: {},
      imagUrl: this.$http.imageUrl,
    }
  },
  computed: {},
  watch: {},
  created() {
    bannerDetail({
      type: '6',
    }).then((res_) => {
      this.contactUsBanner = res_.data
    })
    contactUsInfoInfo().then((res) => {
      console.log(res)
      this.info = res.data
    })

    contactUsInfoList().then((res) => {
      console.log(res)
      this.telData = res.data.list
    })
  },
  mounted() {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.block-contactUs {
  display: flex;
  margin-bottom: 40px;
  .left {
    flex-grow: 1;
    .left-inner {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      .item {
        margin-bottom: 10px;
        height: 50px;
        background: #f5f7f9;
        line-height: 50px;
        display: flex;
        align-items: center;
        i {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          margin-right: 10px;
        }
        &:first-child i {
          background: #007aff;
          color: #fff;
        }
      }
    }
    .grild {
      margin-top: 20px;
      padding: 0 20px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;

      &-item {
        margin-right: 10px;
        margin-bottom: 20px;
        width: 275px;
        padding: 28px 0;
        background: #ffffff;
        border: 1px solid #c2c2c2;
        text-align: center;
        p {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 600;
          color: #222;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:hover {
          border: 1px solid #3d7ae4;
          p {
            color: #3d7ae4;
          }
        }
      }
    }
  }
  .img {
    flex-shrink: 0;
    width: 301px;
    height: 464px;
  }
}
</style>
