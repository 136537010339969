<template>
  <div class="tabs">
    <div
      class="tabs-item"
      v-for="(v, i) in jobDataList"
      :key="i"
      @click="clickTabs(v, i)"
      :class="{ active: cunnerIndex == i }"
    >
      <span>
        {{ v.name }}
      </span>
      <i class="el-icon-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    jobDataList:Array,
    arrIndex:Number
  },
  name: 'Tabs',
  data() {
    return {
      cunnerIndex: 0
    }
  },
  methods: {
    clickTabs(v, i) {
      this.$emit('active', { ...v, i })
      this.cunnerIndex = i
    }
  },
  mounted() {
    this.clickTabs(this.jobDataList[this.arrIndex],this.arrIndex)
  },
  watch:{
    jobDataList(){
      this.clickTabs(this.jobDataList[this.arrIndex],this.arrIndex)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
  width: 100%;

  .tabs-item {
    width: 100%;
    height: 55px;
    border: 1px solid #cccccc;
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    font-size: $mo-font-size-lg;

    i {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      line-height: 24px;
      text-align: center;
      background: #fff;
      color: #222222;
      transition: all 1s ease;
      font-size: $mo-font-size-base;
    }

    &:hover {
      border: 1px solid #3d7ae4;
      background: #3d7ae4;
      color: #fff;

      i {
        transform: translateX(15px);
      }
    }
  }
  .active {
    border: 1px solid #3d7ae4;
    background: #3d7ae4;
    color: #fff;
    i {
      transform: translateX(10px);
    }
  }
}
</style>
