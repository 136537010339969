const course = () => import('./course.vue')
export default {
  path: '/course',
  name: 'Course',
  component: course,
  meta: {
    title: '阅读辅导',
    authLogin: false //是否需要登录
  },
  children: []
}
