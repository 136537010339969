<template>
  <div class="custom-header">
    <el-footer height="100%">
      <!-- <div class="bg-img"></div>  -->
      <!-- 一行三列 -->
      <el-row class="row">
        <el-col :span="8" class="code-img">
          <div class="image">
            <p>公众号</p>
            <img src="../../../assets/images/wx_code1.jpg" alt="" />
            <div class="pup-image1">
              <img src="../../../assets/images/wx_code1.jpg" alt="" />
            </div>
          </div>
          <div class="image">
            <p>小程序</p>
            <img src="../../../assets/images/code.jpg" alt="" />
            <div class="pup-image2">
              <img src="../../../assets/images/code.jpg" alt="" />
            </div>
          </div>
          <div class="image">
            <p>抖音</p>
            <img src="../../../assets/images/dy_code.png" alt="" />
            <div class="pup-image3">
              <img src="../../../assets/images/dy_code.png" alt="" />
            </div>
          </div>
        </el-col>
        <el-col :span="1">
          <div class="line"></div>
        </el-col>
        <el-col :span="8" class="link-style">
          <p>
            图书商城链接：<a href="https://shop418769515.taobao.com/shop/view_shop.htm?https://shop418769515.taobao.com/shop/view_shop.htm?tracelog=twddp&user_number_id=2209293565637" target="_blank"
              >https://shop418769515.taobao.com</a
            >
          </p>
          <p>
            少年儿童1+X公益阅读网链接：<a
              href="https://www.rtprpc.com"
              target="_blank"
              >https://www.rtprpc.com</a
            >
          </p>
        </el-col>
        <el-col :span="1">
          <div class="line"></div>
        </el-col>
        <el-col :span="6">
          <div class="record">
            <p>Copyright &copy; 太原睿图文化传播有限公司</p>
            <p class="active" @click="toOuter('https://beian.miit.gov.cn')">
              晋ICP备2021004382号-2
            </p>
            <p class="jingan">
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14010502050949"
                ><img src="../../../assets/images/jingan.png" />
                <p style="padding: 0 10px 0 10px">
                  晋公网安备 14010502050949号
                </p></a
              >
            </p>
          </div>
        </el-col>
      </el-row>
    </el-footer>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      list: [
        {
          title: '友情链接',
          list: [
            {
              title: 'https://www.rtprpc.com',
              link: 'https://www.rtprpc.com',
            },
          ],
        },
        {
          title: '技术支持',
          list: [
            {
              title: '太原睿图文化传播有限公司',
            },
          ],
        },
        {
          title: '联系我们',
          list: [
            {
              title: '19103424220',
              // link: ''
            },
            {
              title: '19935160953',
              // link: ''
            },
          ],
        },
      ],
    }
  },
  methods: {
    toLink(link) {
      if (link) {
        window.open(link, '_blank')
      } else {
        return false
      }
    },
    toOuter(link) {
      window.open(`${link}`)
    },
  },
}
</script>

<style scoped lang="scss">
.el-footer {
  background: url("../../../assets/images/bg_icon.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}
.custom-header {
  width: 100%;
  background: #535254;
  min-width: $mo-main-width-base;
  .app-header-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-top: $mo-spacing-col-lg;
    li {
      text-align: center;
      color: #fff;
      font-size: $mo-font-size-sm;
      line-height: 35px;
    }
    .title {
      font-size: $mo-font-size-lg;
    }
  }
  .record {
    margin-top: 30px;
    text-align: center;
    p {
      color: #fff;
      font-size: 12px;
      line-height: 30px;
      // padding: 0 20px;
    }
    .active {
      cursor: pointer;
    }
  }
  .jingan a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px 0;
  }
  .jingan img {
    width: 16px;
    height: 16px;
  }
  .row {
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .border {
    margin: 20px 0;
    border-right: 1px solid #fff;
    opacity: 0.28;
  }
  .link-style {
    height: 160px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    display:flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 15px 0;
    }
    a {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .line {
    width: 1px;
    height: 160px;
    background: #fff;
    opacity: 0.28;
  }
  .code-img {
    display: flex;
    align-content: center;

    .image {
      cursor: pointer;
      padding: 10px;
      width: 120px;
      height: 120px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 20px;
      }
      &:nth-child(1):hover {
        .pup-image1 {
          display: block;
        }
      }
      &:nth-child(2):hover {
        .pup-image2 {
          display: block;
        }
      }
      &:nth-child(3):hover {
        .pup-image3 {
          display: block;
        }
      }
      .pup-image1,
      .pup-image2,
      .pup-image3 {
        z-index:9999;
        display: none;
        position: absolute;
        top: -200px;
        left: -20%;
        padding: 20px;
        background: #fff;
        img {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}
</style>
