<template>
  <div class="form-middle">
    <el-row>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-col :span="12">
          <el-form-item label="所在单位名称：" prop="companyName">
            <el-input
              v-model="ruleForm.companyName"
              placeholder="请输入所在单位名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名：" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="mobie">
            <el-input
              v-model="ruleForm.mobie"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item label="职务：" prop="job">
            <el-input
              v-model="ruleForm.job"
              placeholder="请输入职务"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="微信同号：" prop="isWechat">
            <el-radio-group v-model="ruleForm.isWechat" @change="wxChange">
              <el-radio label="是">是</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="微信号" v-if="isShowInput" prop="wechat">
            <el-input
              v-model="ruleForm.wechat"
              placeholder="请输入微信号"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input
              v-model="ruleForm.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合作内容：" prop="content">
            <el-input
              v-model="ruleForm.content"
              placeholder="请填写合作内容"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="btn" @click="submit('ruleForm')">
            <span>提交</span>
          </div>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import { apply } from '@/services/api.js'

export default {
  // 定义属性
  data() {
    return {
      radio: '',
      isShowInput: false, // 是否显示输入微信号,
      ruleForm: {
        name: '',
        companyName: '',
        mobie: '',
        job: '',
        phone: '',
        isWechat: '',
        wechat: '',
        email: '',
        content: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        companyName: [
          {
            required: true,
            message: '请输入单位名称',
            trigger: ['blur', 'change'],
          },
        ],
        mobie: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: ['blur', 'change'],
          },
        ],
        job: [
          {
            required: true,
            message: '请输入职务',
            trigger: ['blur', 'change'],
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: ['blur', 'change'],
          },
        ],
        isWechat: [
          {
            required: true,
            message: '请选择是否微信同号',
            trigger: ['blur', 'change'],
          },
        ],
        wechat: [
          {
            required: true,
            message: '请输入微信号',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ],
        content: [
          {
            required: true,
            message: '请输入合作内容',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    wxChange(e) {
      console.log(e)
      if (e == '否') {
        this.isShowInput = true
        // 否 在对象中增加属性
      } else {
        this.isShowInput = false
        // 是 在对象中删除属性
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apply({
            ...this.ruleForm,
          }).then((res) => {
            if (res.code == 200) {
              this.$emit('PartnerFormChange', 0)
              this.$message({
                message: res.msg,
                type: 'success',
              })
            }
          })
        } else {
          return false
        }
      })
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='scss' scoped>
.form-middle {
  padding: 30px;
}
::v-deep .el-textarea__inner {
  height: 400px;
}
.btn {
  cursor: pointer;
  display: inline-block;
  padding: 10px 120px;
  background: #f52021;
  position: relative;
  left: 50%;
  margin: 20px 0 0 -10%;
  span {
    font-size: 16px;
    color: #ffffff;
  }
  i {
    font-size: 16px;
    color: #ffffff;
  }
}
</style>