const contactUs = () => import('./Cooperation.vue')
export default {
  path: '/Cooperation',
  name: 'Cooperation',
  component: contactUs,
  meta: {
    title: '合作单位',
    authLogin: false //是否需要登录
  },
  children: []
}
