// 懒加载路由
const courseDetails = () => import('./courseDetails.vue')

export default {
  path: '/courseDetails',
  name: 'CourseDetails',
  component: courseDetails,
  meta: {
    title: '课程详情',
    authLogin: false //是否需要登录
  },
  children: []
}
