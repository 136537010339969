// 懒加载路由
const company = () => import('./company.vue')
export default {
  path: '/company',
  name: 'Company',
  component: company,
  meta: {
    title: '企业概况',
    authLogin: false //是否需要登录
  },
  children: []
}
