<template>
  <div class="journalism-item">
    <div class="journalism-item-inner container">
      <el-row>
        <el-col :span="12">
          <div class="item1">
            <el-image :src="icon_20" fit="fill" lazy></el-image>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="item2 pointer" @click="toDetail(Import.id)">
            <div class="tag">重要资讯</div>
            <div class="title">
              {{ Import.title }}
            </div>
            <div class="date">
              <i class="el-icon-discover"></i>
              {{ Import.noticeTime }}
            </div>
            <div class="desc e-3" v-html="Import.content"></div>
          </div>
        </el-col>
        <el-col :span="12" v-for="(noticeItem, i) in noticeList" :key="i">
          <div
            :style="{ background: i == 1 || i == 3 ? '#EEF5FF' : '#F7F8FA' }"
            class="item3 pointer"
            @click="toDetail(noticeItem.id)"
          >
            <div class="left">
              <span>{{ noticeItem.noticeTime | date }}</span>
              <span>{{ noticeItem.noticeTime | specificDate }}</span>
            </div>
            <div class="line"></div>
            <div class="right">
              <div class="title">
                {{ noticeItem.title }}
              </div>
              <div class="desc e-2">{{ noticeItem.content | contentRegx }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-button @click="toJournalism">
        了解更多
        <i class="el-icon-right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import icon_20 from 'assets/images/home/icon_20.png'
export default {
  props: {
    noticeList: {
      type: Array,
    },
    Import: {
      type: Object,
    },
  },
  name: 'JournalismItem',
  data() {
    return {
      icon_20,
    }
  },
  methods: {
    // 跳转到企业新闻
    toJournalism() {
      this.$router.push({
        path: '/journalism',
      })
    },
    // 跳转到详情
    toDetail(id) {
      this.$router.push({
        name: 'JournalismDetails',
        query: { id: id },
      })
    },
  },
  filters: {
    date(value) {
      return value.substring(value.length - 2)
    },
    specificDate(value) {
      return value.substring(0, value.length - 3)
    },
    contentRegx(val) {
      let regx = /<[^>]*>|<\/[^>]*>/gm
      let valRegx1 = val.replace(regx, '')
      let valRegx2 = valRegx1.replace(/&rdquo;/g, '')
      let valRegx3 = valRegx2.replace(/&ldquo;/g, '')
      let valRegx4 = valRegx3.replace(/&mdash;/g, '')
      let valRegx5 = valRegx4.replace(/&middot;/g, '')
      let valRegx6 = valRegx5.replace(/&nbsp;/g, '')
      let valRegx7 = valRegx6.replace(/&hellip;/g, '')
      let valRegx8 = valRegx7.replace(/&lsquo;/g, '')
      return valRegx8
    },
  },
}
</script>

<style scoped lang="scss">
div {
  //outline: #4cd964 1px solid;
}
.el-button {
  background: #ffffff !important;
  border: 1px solid #f52021 !important;
  color: #f52021;
}
.item1 {
  height: 230px;
  .el-image {
    height: 100%;
    width: 100%;
  }
}
.item2 {
  border-right: 1px solid #e7e7eb;
  border-top: 1px solid #e7e7eb;
  display: flex;
  flex-direction: column;
  height: 230px;
  padding: $mo-spacing-col-lg;
  .tag {
    font-size: $mo-font-size-base;
    color: #fff;
    background: $mo-main-color;
    width: 100px;
    border-top-left-radius: $mo-border-radius-base;
    border-bottom-right-radius: $mo-border-radius-base;
    line-height: 30px;
    text-align: center;
  }
  .title {
    font-size: $mo-font-size-lg;
    font-weight: 600;
    line-height: 40px;
  }
  .date {
    font-size: $mo-font-size-sm;
    color: $mo-color-subtitle;
  }
  .desc {
    font-size: $mo-font-size-base;
    color: $mo-color-subtitle;
    line-height: 30px;
    max-height: 60px;
  }
}
.item3 {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1000ms;

  .left {
    width: 95px;
    height: 95px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      &:first-child {
        font-size: 30px;
        font-weight: 600;
      }
      &:last-child {
        font-size: $mo-font-size-sm;
      }
    }
  }
  .line {
    height: 60px;
    width: 1px;
    background: #999;
    overflow: hidden;
  }
  .right {
    padding-left: $mo-spacing-col-lg;
    height: 95px;
    .title {
      font-size: $mo-font-size-base;
      font-weight: 600;
      line-height: 35px;
    }
    .desc {
      font-size: $mo-font-size-sm;
      color: $mo-color-subtitle;
      line-height: 30px;
      max-height: 60px;
    }
  }

  &:hover {
    transform: scale(1.1);
    color: $mo-main-color;
    .desc {
      color: $mo-main-color;
    }
  }
}
</style>
