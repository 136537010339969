const ContactUsDetails = () => import('./ContactUsDetails.vue')
export default {
  path: '/ContactUsDetails',
  name: 'ContactUsDetails',
  component: ContactUsDetails,
  meta: {
    title: '详情',
    authLogin: false //是否需要登录
  },
  children: []
}
