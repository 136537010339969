<template>
  <div class="Cooperation">
    <AppHeader :default-index="4" :isShadow="true"></AppHeader>
    <div class="middle">
      <h3 class="title">{{data.title}}</h3>
      <p class="desc" v-html="data.content"></p>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import { publishedInfo } from '@/services/api.js'

export default {
  name: 'Issue',
  components: {
    AppHeader,
    AppFooter,
  },
  data: function () {
    return {
      journalistBanner: [],
      journaDetails: [],
      arr: {},
      loading: true,
      data: {}
    }
  },
  computed: {},
  watch: {},
  created() {
      publishedInfo().then( res => {
          this.data = res.data
      })
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.middle {
  width: 1200px;
  margin: 60px auto;
  min-height: 600px;
  .title {
      margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #222222;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    line-height: 30px;
  }
}
</style>