const bookStore = () => import('./bookStore.vue')
export default {
  path: '/bookStore',
  name: 'BookStore',
  component: bookStore,
  meta: {
    title: '图书商城',
    authLogin: false //是否需要登录
  },
  children: []
}
