<template>
  <div class="OneXItem">
    <div class="item-inner">
      <div class="left">
        <div class="left-con">
          <div class="img">
            <img src="~assets/images/home/icon_1.png" alt="" />
          </div>
          <div class="left_inner">
            <div class="title">
              山西省少年儿童1+X公益阅读志愿服务项目
            </div>
            <div class="author_inner">
              <div class="item">
                开展时间：2016年9月
              </div>
              <div class="item">
                注册志愿者人数：426人
              </div>
              <div class="item">
                项目负责人：薛张华
              </div>
            </div>
            <div class="desc">
              项目概述及亮点成效： 山西省少年儿童1+X公益阅读项目以“培育三晋阅读沃土，播种书香新风尚”为宗旨，秉承“小手拉大手 ”理念，以少儿阅读推广为切入点，以“书香校园”带动“书香家庭”、“书香社会”建设。它是落实全民阅读精神的一项重要举措，是贯彻落实《山西省全民阅读促进条例》的具体实践。
              六年间，足迹遍及全省11地市、83个县（市、区），形成了覆盖乡镇、山区，包含省市（区）县各级学校的分布格局。共培养志愿者(阅读教育专家、学者)426人，组织校园阅读研讨会846场、学校内部的阅读推广活动600余场、家长阅读教育培训会600多场，受益学校146所，服务阅览室146个，提供图书300余万册，惠及学生30余万人，家长50余万人。
            </div>
          </div>
          <el-button @click="toOneX()">
            了解更多
            <i class="el-icon-d-arrow-right"></i>
          </el-button>
        </div>
      </div>
      <div class="right">
        <div class="img">
          <img src="~assets/images/home/icon_6.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OneXItem',
  data() {
    return {}
  },
  methods: {
    toOneX() {
      this.$router.push({
        path: '/oneX'
      })
    }
  }
}
</script>

<style scoped lang="scss">
div {
  //outline: #4cd964 1px solid;
}

.OneXItem {
  padding-left: 88px;
  min-width: $mo-main-width-base;
  width: 100%;
  .item-inner {
    position: relative;
    display: flex;
    .left {
      position: relative;
      left: 0;
      .left-con {
        position: relative;
        z-index: 1;
        .img {
          width: 1086px;
          height: 489px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .left_inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          padding-left: 271px;
          padding-top: 57px;
          padding-bottom: 75px;
          padding-right: 81px;
          color: #fff;
          .title {
            font-size: $mo-font-size-xl;
            line-height: 40px;
            color: #fff;
          }
          .author_inner {
            display: flex;
            justify-content: space-between;
            opacity: 0.8;
            line-height: 60px;
          }
          .desc {
            opacity: 0.8;
            font-size: $mo-font-size-base;
            line-height: 35px;
            letter-spacing: 1px;
          }
        }
        .el-button {
          position: absolute;
          right: 80px;
          bottom: 27px;
          background: rgba(255, 255, 255, 0.3);
          color: #fff;
          border: none;
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
    }
  }
}
</style>
