<template>
  <div class="course-item">
    <div class="title">
      <TitleItem title="企业发展历程"
                 desc="Enterprise Development History"
                 center></TitleItem>
    </div>
    <div class="course-item-inner">
      <div class="course-item-inner-bg">
        <img src="~images/compary/icon_32.png"
             alt="" />
      </div>
      <div class="course-item-inner-con container">
        <div class="block">
          <div class="left">
            <div v-html="leftList.content">
            </div>
          </div>
          <div class="right">
            <div class="item"
                 v-for="(v, i) in rightList"
                 :key="i">
              <div class="icon">
                <div class="icon-inner"></div>
                <div class="icon-con">
                  {{ v.year }}
                </div>
              </div>
              <div class="desc" v-html="v.content">
              </div>
            </div>
<!--            <div class="item">-->
<!--              <div class="icon">-->
<!--                <div class="icon-inner"-->
<!--                     style="opacity: 1"></div>-->
<!--                <div class="icon-con">2020</div>-->
<!--              </div>-->
<!--              <div class="desc">-->
<!--                <div class="desc-item">-->
<!--                  <div class="desc-inner">年初</div>-->
<!--                  <div class="desc-con">-->
<!--                    整合省内优质教师、教学资源，推出线上“与名师共读一本书”和小升初知识梳理与衔接公益网络课程-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="desc-item">-->
<!--                  <div class="desc-inner">3月</div>-->
<!--                  <div class="desc-con">-->
<!--                    <div>-->
<!--                      我们被山西省文明办、民政厅等14家单位授予2019年山西省“最佳志愿服务项目”-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      我们被中央宣传部、中央文明办等十多家单位授予“全国最佳志愿服务项目”-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="desc-item">-->
<!--                  <div class="desc-inner">8月</div>-->
<!--                  <div class="desc-con">-->
<!--                    省委宣传部专门召开颁奖会议，山西日报、山西电视台等十多家省内外新闻媒体给予报道。会上，省委常委、宣传部长吕岩松同志给项目负责人薛张华同志颁发了证书和奖牌，并号召相关部门对此项目予以支持，把这项公益活动在山西开展的更加扎实-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleItem from '@/components/content/company/TitleItem'

export default {
  props: {
    leftList: {
      type: [Object, Array],
      default: () => { return {} }
    },
    rightList: {
      type: [Object, Array],
      default: () => { return [] }
    }
  },
  name: 'CourseItem',
  components: {
    TitleItem
  },
  data () {
    return {
      screenWidth: document.body.clientWidth,
      // leftList: [
      //   '2016年开展至今的4年时间里',
      //   '项目已成功落地我省11个市、78个县（区）',
      //   '培养志愿者(阅读教育专家、学者)386人人',
      //   '学校内部的阅读推广活动500余场',
      //   '家长阅读教育培训会500多场',
      //   '受益学校78所、阅览室78间、图书467000册',
      //   '惠及学生189000多人、家长278000多人',
      //   '今年主动申报要求加入此项目的学校就达156所',
      //   '惠及人数将达到25-30万人',
      //   '随着项目的深入推进，社会影响力逐步扩大，取得的社会效益越来越明显。'
      // ],
      // rightList: [
      //   {
      //     year: '2009',
      //     desc:
      //       '太原睿图文化传播有限公司于2009年成立，成立之初主做中小学教辅，是山西新华连锁的十佳供应商'
      //   },
      //   {
      //     year: '2015',
      //     desc:
      //       '致力于少儿阅读读书推广工作，得到了省宣传部、省文明办、省教育厅等多家单位的支持'
      //   },
      //   {
      //     year: '2016',
      //     desc: '在山西省内启动“全民阅读校园行”试点工作，探索行之有效的推广方法'
      //   },
      //   {
      //     year: '2017',
      //     desc:
      //       '布局全省，选择有代表性的试点学校开展工作，覆盖乡镇、山区，县城，市级和省级学校，并已成功合作了四十多家学校'
      //   },
      //   {
      //     year: '2018',
      //     desc: '总结经验，推出“山西省少年儿童1+X公益阅读计划”项目。'
      //   },
      //   {
      //     year: '2019',
      //     desc:
      //       '联合省教科院设立山西省教育科学“十三五”规划课题，并在全省建立课题实验学校，成立专家团队，大力推广公益阅读活动，将在全省设立三百家阅读实验基地校和一百家校外阅读馆，搭建专门的阅读APP'
      //   },
      //   {
      //     year: '2019',
      //     desc:
      //       '联合省教科院设立山西省教育科学“十三五”规划课题，并在全省建立课题实验学校，成立专家团队，大力推广公益阅读活动，将在全省设立三百家阅读实验基地校和一百家校外阅读馆，搭建专门的阅读APP'
      //   },
      //   {
      //     year: '2019',
      //     desc:
      //       '联合省教科院设立山西省教育科学“十三五”规划课题，并在全省建立课题实验学校，成立专家团队，大力推广公益阅读活动，将在全省设立三百家阅读实验基地校和一百家校外阅读馆，搭建专门的阅读APP'
      //   }
      // ]
    }
  },
  methods: {
    leftFormat (v) {
      return v.replace(
        /([\d]+)/g,
        "<span style=' font-size: 20px;font-weight: 600;'>$1</span>"
      )
    }
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (that.screenWidth = document.body.clientWidth)
    }
  },
  beforeDestroy () { }
}
</script>

<style scoped lang="scss">
.block {
  display: flex;
  height: 705px;
  min-width: $mo-main-width-base;
  .left {
    width: 470px;
    height: 100%;
    font-size: $mo-font-size-sm;
    line-height: 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 2px;
  }

  .right {
    overflow: hidden;
    overflow-y: auto;
    width: calc(100% - 470px);
    height: 100%;
    color: #fff;
    font-size: $mo-font-size-sm;
    padding: 20px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(37, 108, 233, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(37, 108, 233, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    .item {
      display: flex;
      margin-top: 25px;
      .icon {
        display: flex;
        align-items: center;
        font-size: 16px;
        .icon-inner {
          width: 10px;
          height: 10px;
          background: #fff;
          opacity: 0.8;
          border-radius: $mo-border-radius-circle;
        }
        .icon-con {
          margin: 0 20px;
        }
      }
      .desc {
        line-height: 28px;
        .desc-item {
          display: flex;
          .desc-inner {
            flex-shrink: 0;
            margin-top: 4px;
            background: #fff;
            color: #3d7ae4;
            width: 38px;
            height: 20px;
            border-radius: 2px;
            line-height: 20px;
            text-align: center;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.course-item {
  width: 100%;

  .course-item-inner {
    margin-top: $mo-spacing-col-lg;
    position: relative;

    .course-item-inner-bg {
      height: 705px;
      min-width: $mo-main-width-base;
      img {
        object-fit: cover;
      }
    }

    .course-item-inner-con {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
