<template>
  <div class="qr-code">
    <div class="qr-code-inner">
      <div class="qr-code-inner-bg">
        <img src="~images/course/icon_56.png" alt="" />
      </div>
      <div class="qr-code-inner-con container">
          <div class="img">
          <img src="~images/course/icon_58.jpg" alt="" />
        </div>
        <div class="img">
          <img src="~images/wxxd_code.png" alt="" />
        </div>
        <div class="right">
          <div>手机微信扫描左边二维码</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'qrCode'
}
</script>

<style scoped lang="scss">
.qr-code {
  .qr-code-inner {
    min-width: $mo-main-width-base;
    position: relative;

    .qr-code-inner-bg {
      height: 256px;
      padding-left: 88px;

      .img {
        object-fit: cover;
      }
    }

    .qr-code-inner-con {
      position: absolute;
      left: 0;
      right: 88px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      width: $mo-main-width-base;
      padding-left: 100px;

      .img {
        width: 150px;
        height: 150px;
        margin-right: 10px;

      }

      .right {
        line-height: 50px;
        margin-left: $mo-spacing-row-base;
        font-size: $mo-font-size-lg;
      }
    }
  }
}
</style>
