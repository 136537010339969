<template>
  <div class="journalism-details">
    <AppHeader :default-index="4" :isShadow="true"></AppHeader>
    <!-- <div class="breadcrumb container">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/journalism' }"
          >企业新闻</el-breadcrumb-item
        >
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <!-- <div class="line container"></div> -->
    <div class="journalism-details-con container">
      <div class="journalism-details-con-title">
        {{ noticeObj.title }}
      </div>
      <div class="date">
        <i class="el-icon-discover" v-if="noticeObj.noticeTime"></i>
        {{ noticeObj.noticeTime }}
      </div>
      <div class="con" v-if="noticeObj.content">
        <div class="valContent" v-html="noticeObj.content"></div>
      </div>
      <div class="con" v-if="noticeObj.content"></div>
      <div class="line"></div>
      <div class="butt">没有更多了</div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import { familyEducationDetail, readEducationDetail } from '@/services/api.js'
import config from '@/utils/config'

export default {
  name: 'journalismDetails',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      imageUrl: '',
      noticeObj: {},
      journaDetailsBanner: [],
    }
  },
  created() {
    this.imageUrl = config.imageUrl
    // 家庭教育详情
    if (this.$route.query.type == 'family') {
      familyEducationDetail({
        id: this.$route.query.id,
      }).then((res_) => {
        this.noticeObj = res_.data
      })
    } else {
      readEducationDetail({
        id: this.$route.query.id,
      }).then((res_) => {
        this.noticeObj = res_.data
      })
    }
  },
  filters: {
    contentRegx(val) {
      let regx = /<[^>]*>|<\/[^>]*>/gm
      let valRegx1 = val.replace(regx, '')
      let valRegx2 = valRegx1.replace(/&rdquo;/g, '')
      let valRegx3 = valRegx2.replace(/&ldquo;/g, '')
      let valRegx4 = valRegx3.replace(/&mdash;/g, '')
      let valRegx5 = valRegx4.replace(/&middot;/g, '')
      let valRegx6 = valRegx5.replace(/&nbsp;/g, '')
      let valRegx7 = valRegx6.replace(/&hellip;/g, '')
      let valRegx8 = valRegx7.replace(/&lsquo;/g, '')
      let valRegx9 = valRegx8.replace(/&rsquo/g, '')
      return valRegx9
    },
  },
}
</script>

<style scoped lang="scss">
.breadcrumb {
  padding: $mo-spacing-row-base;
  font-size: $mo-font-size-base;
  ::v-deep.el-breadcrumb__inner {
    color: $mo-main-color;
    font-weight: 600;
  }
  ::v-deep.is-link {
    color: $mo-text-color-grey;
    font-weight: 500;
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #bfc6cc;
}
.journalism-details-con {
  margin-top: 20px;
  min-height: 650px;
  .con {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 37px;
    .valContent {
      text-indent: 1cm;
    }
  }
  .journalism-details-con-title {
    text-align: center;
    display: block;
    font-size: $mo-font-size-xl;
    font-weight: 600;
    line-height: 60px;
  }
}
.butt {
  color: $mo-text-color-placeholder;
  text-align: center;
  line-height: 70px;
}
</style>
