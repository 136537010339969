<template>
  <div class="job-title-item " @click="skip(item.id)">
    <div class="title">
      {{item.name}}
    </div>
    <div class="tags">
      {{item.address}} | {{item.education}} | {{item.workDay}} | 招聘人数：{{item.number}} | {{item.createDate}}
    </div>
    <div class="desc e-2" v-html="item.content">
      <!-- 岗位职责：1、负责市场调研和需求分析；2、负责年度销售的预测，目标的制定及分解；3、确定销售部门目标体系和销售配额；4、制定销岗位职责：1、负责市场调研和需求分析；2、负责年度销售的预测，目标的制定及分解；3、确定销售部门目标体系和销售配额；4、制定销 -->
    </div>
    <div class="pic">{{item.salary}} <span>元/月</span></div>
  </div>
</template>

<script>
export default {
  props:{
    item:Object,
    jobData:Array,
    index:Number
  },
  name: 'JobTitleItem',
  methods: {
    skip(id) {
      let jobDataList = this.jobData
      this.$router.push({
        name: 'details',
        query: { id: id, jobDataList, index: this.index }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.job-title-item {
  cursor: pointer;
  height: 200px;
  width: 100%;
  box-shadow: 0px 6px 20px 0px rgba(204, 204, 204, 0.3);
  margin-top: 20px;
  padding: 20px;
  position: relative;
  transition: all 500ms ease;
  &:hover {
    transform: scale(1.1);
  }
  .title {
    font-size: $mo-font-size-lg;
    font-weight: 600;
    line-height: 30px;
  }
  .tags {
    font-size: $mo-font-size-base;
    color: #999;
    line-height: 35px;
  }
  .desc {
    font-size: $mo-font-size-base;
    color: #999;
    line-height: 40px;
    max-height: 60px;
  }
  .pic {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: $mo-font-size-xl;
    color: $mo-main-color;
    font-weight: 600;
    span {
      font-size: $mo-spacing-row-sm;
      color: #222;
    }
  }
}
</style>
