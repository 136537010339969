<template>
  <div class="honor-item">
    <div class="title">
      <TitleItem
        title="企业荣誉资质"
        desc="Enterprise Honors and Qualifications"
        center
      ></TitleItem>
    </div>
    <div class="honor-item-inner container">
      <div class="left">
        <div class="img">
          <img src="~images/compary/icon_22.png" alt="" />
        </div>
        <div class="desc">
          2020年8月26日，省委宣传部专门召开颁奖会议，山西日报、山西电视台等十多家省内外新闻媒体给予报道。会上，省委常委、宣传部长吕岩松同志给项目负责人薛张华同志颁发了证书和奖牌，并号召相关部门对此项目予以支持，把这项公益活动在山西开展的更加扎实。
        </div>
      </div>
      <div class="imglist">
        <el-row>
          <el-col :span="12">
            <img src="~images/compary/icon_25.png" alt="" />
          </el-col>
          <el-col :span="12">
            <img src="~images/compary/icon_26.png" alt="" />
          </el-col>
          <el-col :span="12">
            <img src="~images/compary/icon_27.png" alt="" />
          </el-col>
          <el-col :span="12">
            <img src="~images/compary/icon_28.png" alt="" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import TitleItem from '@/components/content/company/TitleItem'

export default {
  components: {
    TitleItem
  },
  name: 'HonorItem'
}
</script>

<style scoped lang="scss">
div {
  //outline: #4cd964 1px solid;
}

.honor-item {
  margin: 20px 0 50px 0;
  .honor-item-inner {
    display: flex;
    .left {
      margin-top: 30px;
      margin-right: 68px;
      .img {
        width: 533px;
      }
      .desc {
        width: 533px;
        padding: 17px;
        background: #f7f8fa;
        color: #4c4c4c;
        font-size: $mo-font-size-sm;
        line-height: 21px;
      }
    }
    .imglist {
      text-align: center;
      img {
        width: 284px;
        margin-top: 30px;
      }
    }
  }
}
</style>
