<template>
  <div class="book-list">
    <div class="book-list-inner">
      <div class="tabs">
        <span class="tabs-title">全部</span>
        <ul class="tabs-ul">
          <li
            class="tabs-item pointer"
            v-for="(v, i) in allList"
            :key="i"
            :class="{ active: i == allIndex }"
            @click="clickAllList()"
          >
            全部
          </li>
        </ul>
      </div>
      <div class="tabs">
        <span class="tabs-title">年龄</span>
        <ul class="tabs-ul">
          <li
            class="tabs-item pointer"
            v-for="(v, i) in categoryList"
            :key="i"
            :class="{ active: i == ageCunnerIndex }"
            @click="clickTabs(v, i)"
          >
            {{ v.label }}
          </li>
        </ul>
      </div>
      <div class="tabs">
        <span class="tabs-title">图书</span>
        <ul class="tabs-ul">
          <li
            class="tabs-item pointer"
            v-for="(v, i) in bookList"
            :key="i"
            :class="{ active: i == bookCunnerIndex }"
            @click="clickBookTabs(v, i)"
          >
            {{ v.name }}
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class="line" v-if="line"></div> -->
    <div class="search-conent">
      <div class="conent-item" v-for="(item,index) in searchList" :key="index">{{ item.label }}</div>

      <div class="conent-desc">图书检索结果如下：</div>
    </div>

    <div class="bool-list-body">
      <div class="bool-list-body-inner container">
        <div v-if="length > 0" class="bool-list-body-inner-con">
          <slot></slot>
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookList",
  props: {
    length: Number,
    line: Boolean,
    categoryList: Array,
    bookList: Array
  },
  data() {
    return {
      ageCunnerIndex: -1,
      bookCunnerIndex: -1,
      ageData: {},
      bookData: {},
      allList: [
        {
          laebl: "全部"
        }
      ],
      searchList: [],
      allIndex: 0
    };
  },
  methods: {
    clickTabs(v, i) {
      let parms = {
        value: v.value,
        id: this.bookData.id
      };
      this.$emit("change", { ...parms, i });
      this.ageCunnerIndex = i;
      this.ageData = v;
      this.allIndex = -1;
      this.searchList.push({ label: v.label,  id: "02" });
      this.searchList.map((item, index) => {
        if(item.id === "01") {
          this.searchList = [{ label: v.label,  id: "02" }];
        }
        if(item.id === "02" && item.label !== v.label) {
          this.searchList.splice(index,1)
        }

        if(item.id === "02" && item.label === v.label) {
          this.searchList.splice(index,1)
        }
      })
    },
    clickBookTabs(v, i) {
      let parms = {
        value: this.ageData.value,
        id: v.id
      };
      this.$emit("change", { ...parms, i });
      this.bookCunnerIndex = i;
      this.bookData = v;
      this.allIndex = -1;

      this.searchList.push({ label: v.name,  id: "03" });
      this.searchList.map((item, index) => {
        if(item.id === "01") {
          this.searchList = [{ label: v.name,  id: "03" }];
        }

        if(item.id === "03" && item.label !== v.name) {
          this.searchList.splice(index,1)
        }

        if(item.id === "03" && item.label === v.name) {
          this.searchList.splice(index,1)
        }
      })

    },
    clickAllList() {
      this.searchList = [{ label: "全部", id: "01" }];
      let parms = {
        value: "",
        id: ""
      };
      this.$emit("change", { ...parms });
      this.bookCunnerIndex = -1;
      this.ageCunnerIndex = -1;
      this.allIndex = 0;
    }
  },
  mounted() {
    // 请求全部
    this.clickAllList();
    this.searchList = [{ label: "全部", id: "01" }];
    // if (this.categoryList != undefined && this.bookList != undefined) {
    //   this.categoryList[this.ageCunnerIndex] &&
    //     this.clickTabs(
    //       this.categoryList[this.ageCunnerIndex],
    //       this.ageCunnerIndex
    //     )
    //   this.bookList[this.bookCunnerIndex] &&
    //     this.clickBookTabs(
    //       this.bookList[this.bookCunnerIndex],
    //       this.bookCunnerIndex
    //     )
    // }
  }
  // watch: {
  //   categoryList() {
  //     this.clickTabs(
  //       this.categoryList[this.ageCunnerIndex],
  //       this.ageCunnerIndex
  //     )
  //   },
  //   bookList() {
  //     this.clickBookTabs(
  //       this.bookList[this.bookCunnerIndex],
  //       this.bookCunnerIndex
  //     )
  //   },
  // },
};
</script>

<style scoped lang="scss">
div {
  //outline: #4cd964 1px solid;
}

.book-list {
  padding-top: 20px;
  width: 100%;
  background: #f5f7f9;

  .book-list-inner {
    width: 1180px;
    padding: 10px;
    background: #fff;
    margin: 0 auto 20px;

    .tabs {
      border-bottom: 1px dashed #d6d9e1;
      display: flex;

      &-title {
        padding: 15px;
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        border-right: 1px dashed #d6d9e1;
        flex-shrink: 0;
      }

      &-ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // &::-webkit-scrollbar {
        //   /*滚动条整体样式*/
        //   width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        //   height: 4px;
        //   scrollbar-arrow-color: red;
        // }
        // &::-webkit-scrollbar-thumb {
        //   /*滚动条里面小方块*/
        //   border-radius: 5px;
        //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //   background: rgba(0, 0, 0, 0.2);
        //   scrollbar-arrow-color: red;
        // }
        // &::-webkit-scrollbar-track {
        //   /*滚动条里面轨道*/
        //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //   border-radius: 0;
        //   background: rgba(0, 0, 0, 0.1);
        // }
      }

      &-item {
        flex-shrink: 0;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 55px;
      }

      &:last-child {
        border-bottom: none;
      }

      .active {
        color: #fd0002;
      }
    }
  }

  .search-conent {
    width: 1200px;
    margin: 0 auto;
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .conent-item {
      flex-shrink: 0;
      padding: 7px 15px;
      background: rgba(245, 32, 33, 0.06);
      border: 1px solid #f52021;
      border-radius: 4px;
      color: #fd0002;
      margin-right: 20px;
    }

    .conent-desc {
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
  }

  .no-data {
    width: 100%;
    line-height: 80px;
    font-size: 20px;
    text-align: center;
  }

  .bool-list-body {
    .bool-list-body-inner-con {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
