<template>
  <div class="details-item">
    <div class="date">
      <div class="date-inner">发布时间：{{ jobDetail.createDate | date }}</div>
      <div class="date-inner">有效日期：{{ jobDetail.endDate | date }}</div>
    </div>
    <div class="job">
      <div class="title">{{ jobDetail.name }}</div>
      <div class="education">
        <span>学历：{{ jobDetail.education }};</span>
        <span>经验：{{ jobDetail.workDay }};</span>
        <span>招聘人数：{{ jobDetail.number }};</span>
      </div>
      <div class="pic">
        {{ jobDetail.salary }}
        <span>元/月</span>
      </div>
    </div>
    <div class="job-desc">
      <div class="title">职位描述</div>
      <div class="inner" v-html="jobDetail.content"></div>
    </div>
    <div class="job-desc">
      <div class="title">工作地址</div>
      <div class="job-desc-inner">
        {{ jobDetail.position }}
      </div>
      <div
        id="container"
        ref="map"
        style="width: 100%; height: 400px"
      ></div>
    </div>
  </div>
</template>

<script>
// import Map from './map'

export default {
  // components: {
  //   Map,
  // },
  props: {
    jobDetail: Object,
  },
  name: 'detailsItem',
  data() {
    return {
      latitude: '',
      longitude: '',
      conter: null,
      map: null,
      markerLayer: null,
    }
  },
  created() {},
  mounted() {
    // this.init
  },
  methods: {
    init() {
      this.markerLayer = null
      if (!this.jobDetail.position) {
        return
      }
      //初始化地图
      if (!this.map) {
        this.map = new window.TMap.Map('container', {
          center: new window.TMap.LatLng(this.latitude, this.longitude),
          zoom: 17.2,
          rotation: 45,
          pitch: 20,
        })
      } else {
        this.map.panTo(new window.TMap.LatLng(this.latitude, this.longitude))
      }
      //初始化marker
      // this.markerLayer = new window.TMap.MultiMarker({
      //   id: 'marker-layer', //图层id
      //   map: this.map,
      //   styles: {
      //     //点标注的相关样式
      //     marker: new window.TMap.MarkerStyle({
      //       width: 25,
      //       height: 35,
      //       anchor: { x: 16, y: 32 },
      //       src:
      //         'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png',
      //     }),
      //   },
      //   geometries: [
      //     {
      //       //点标注数据数组
      //       id: 'demo',
      //       // styleId: 'marker',
      //       position: new window.TMap.LatLng(this.latitude, this.longitude),
      //       properties: {
      //         title: 'marker',
      //       },
      //     },
      //   ],
      // })
    },
  },
  filters: {
    date(val) {
      if (val) {
        return val.slice(0, 10)
      }
    },
    contentRegx(val) {
      console.log(val)
      let regx = /<[^>]*>|<\/[^>]*>/gm
      console.log(regx)
      let valRegx1 = val.replace(regx, '')
      let valRegx2 = valRegx1.replace(/&rdquo;/g, '')
      let valRegx3 = valRegx2.replace(/&ldquo;/g, '')
      let valRegx4 = valRegx3.replace(/&mdash;/g, '')
      let valRegx5 = valRegx4.replace(/&middot;/g, '')
      let valRegx6 = valRegx5.replace(/&nbsp;/g, '')
      let valRegx7 = valRegx6.replace(/&hellip;/g, '')
      let valRegx8 = valRegx7.replace(/&lsquo;/g, '')
      let valRegx9 = valRegx8.replace(/&rsquo/g, '')
      return valRegx9
    },
  },
  watch: {
    jobDetail() {
      console.log(this.jobDetail)
      if (this.jobDetail.latitude) {
        this.latitude = parseFloat(this.jobDetail.latitude)
        this.longitude = parseFloat(this.jobDetail.longitude)
        this.init()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.details-item {
  background: #f5f7f9;
  padding: 40px;

  .date {
    display: flex;

    .date-inner {
      color: #888888;

      &:last-child {
        margin-left: 60px;
      }
    }
  }

  .job {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 25px 0;

    .title {
      font-size: 28px;
    }
    .education {
      span {
        margin: 0 5px;
      }
    }
    .pic {
      color: #f52021;
      font-size: 28px;

      span {
        font-size: 14px;
        color: #222;
      }
    }
  }

  .job-desc {
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 60px;
    }

    .job-desc-inner,
    .inner {
      color: #6c6c6c;
      line-height: 30px;
    }

    .inner {
      display: flex;
      flex-direction: column;
    }
  }
}
.map {
  .amap-marker {
  }
  ::v-deep.amap-marker-label {
    border-color: #999;
    padding: 5px;
  }
}
</style>
