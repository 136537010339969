<template>
  <div class="company">
    <AppHeader :default-index="1"></AppHeader>
    <Banner :list="companyBanner"></Banner>
    <SynopsisItem></SynopsisItem>
    <CourseItem :leftList="leftList" :rightList="rightList"></CourseItem>
    <HonorItem></HonorItem>
    <app-footer></app-footer>
  </div>
</template>
<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import SynopsisItem from '@/components/content/company/SynopsisItem'
import CourseItem from '@/components/content/company/CourseItem'
import HonorItem from '@/components/content/company/HonorItem'
import { bannerDetail } from '@/services/api.js'
import { leftList, rightList } from "../../services/api";
export default {
  name: 'company',
  components: {
    AppHeader,
    AppFooter,
    Banner,
    SynopsisItem,
    CourseItem,
    HonorItem
  },
  data() {
    return {
      companyBanner: [],
      leftList: [],
      rightList: {}
    }
  },
  created() {
    bannerDetail({
      type: '1'
    }).then(res_ => {
      this.companyBanner = res_.data
    })
    leftList().then(res => {
      this.leftList = res.data

    })
    rightList().then(res => {
      this.rightList = res.data
      console.log(  this.rightList )

    })
  }
}
</script>

<style scoped></style>
