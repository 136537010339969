import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/assets/scss/element-variables.scss'
import http from '@/utils/http'
import VueAMap from 'vue-amap'
import config from '@/utils/config'

//在main.js增加这段话 （能清除之前缓存的地图信息）
const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))
amapKeys.forEach(key => {
  localStorage.removeItem(key)
})
Vue.use(ElementUI)
Vue.prototype.$http = http

Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: config.GDKEY,
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType'],
  v: '1.4.4'
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
