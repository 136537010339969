// 懒加载路由
const Lease = () => import('./Lease.vue')

export default {
  path: '/Lease',
  name: 'Lease',
  component: Lease,
  meta: {
    title: '图书租借',
    authLogin: false //是否需要登录
  },
  children: []
}
