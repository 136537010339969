<template>
  <div class="joinUs-inner container">
    <div class="title">
      <div class="title-inner">
        <div class="icon"></div>
        <div class="title-con">
          太原睿图文化传播有限公司招聘信息
        </div>
      </div>
    </div>
    <div class="list">
      <JobTitleItem
        v-for="(v, i) in jobData"
        :key="i"
        :item="v"
        :index="i"
        :jobData="jobData"
      ></JobTitleItem>
    </div>
  </div>
</template>

<script>
import JobTitleItem from '@/components/content/joinUs/JobTitleItem'
import { jobList } from '@/services/api.js'

export default {
  name: 'index',
  components: {
    JobTitleItem
  },
  data() {
    return {
      jobData: []
    }
  },
  created() {
    jobList({
      pageNo: '1',
      pageSize: '100'
    }).then(res_ => {
      this.jobData = res_.data
      console.log(res_.data)
      
    })
  }
}
</script>

<style scoped lang="scss">
.joinUs-inner {
  padding: 40px 0;
  .title {
    font-size: $mo-font-size-xl;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #222222;
    line-height: 42px;
    .title-inner {
      display: flex;
      align-items: center;
    }
    .icon {
      width: 12px;
      height: 20px;
      background: $mo-main-color;
      transform: skewX(-10deg);
      margin-right: $mo-spacing-row-sm;
    }
  }
}
</style>
