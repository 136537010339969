<template>
  <div class="books-item">
    <div class="books-item-inner">
      <div class="top" @click="toBookDetails(item.id)">
        <div class="img">
          <el-image
            :src="`${imageUrl + item.mainImage}`"
            lazy
            fit="fill"
          ></el-image>
        </div>
      </div>
      <div class="books-item-con">
        <div class="title">
          {{ item.name }}
        </div>
        <div class="desc e-2" v-html="item.remarks">
          <!-- 日本精选专注力培养大书——儿童思维专注力训练 -->
        </div>
        <div class="price">
          <div class="newPrice">
            <span>￥</span>
            <span>{{ item.sellPrice }}</span>
          </div>
          <div class="oldPrice">
            <span>￥</span>
            <span>{{ item.bazaarPrice }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon_3 from 'images/home/icon_3.png'
import config from '@/utils/config'

export default {
  props: {
    item: Object,
  },
  name: 'BooksItem',
  data() {
    return {
      icon_3,
      imageUrl: '',
    }
  },
  methods: {
    toBookDetails(id) {
      let routerPuth = this.$router.resolve({
        path: '/bookStoreDetails',
        query: { id: id },
      })
      window.open(routerPuth.href, '_blank')
    },
  },
  created() {
    this.imageUrl = config.imageUrl
  },
}
</script>

<style scoped lang="scss">
div {
  //outline: #4cd964 1px solid;
}

.books-item {
  width: $mo-main-width-base/5;
  padding: $mo-spacing-col-sm;
  .books-item-inner {
    cursor: pointer;
    .top {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      .img {
        width: 200px;
        height: 200px;
      }
    }
    .books-item-con {
      .title {
        font-size: $mo-font-size-lg;
        font-weight: 600;
        padding: 20px 0 15px 0;
      }
      .desc {
        font-size: $mo-font-size-base;
        color: #999;
        line-height: 24px;
      }
      .price {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .newPrice {
          font-size: 18px;
          font-weight: 600;
          color: #f52021;
          line-height: 42px;

          span:last-child {
            font-size: 28px;
          }
        }
        .oldPrice {
          margin-left: 20px;
          color: #aaaaaa;
          font-size: 18px;
          text-decoration: line-through;
        }
      }
    }
  }
}
</style>
