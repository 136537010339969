<template>
  <div :class="['custom-header', isShadow ? 'shadow' : '']">
    <div class="app-header container">
      <el-header height="78px">
        <div class="app-header-inner">
          <div class="app-header-inner-left">
            <a href="/" class="logo">
              <div class="logo-bg">
                <img src="~assets/images/home/icon_7.png" />
              </div>
            </a>
          </div>
          <div class="app-header-inner-right">
            <div class="tabs">
              <div
                class="tabs-item pointer"
                v-for="(v, i) in tabsList"
                :key="i"
                :class="{ active: i == cunnerIndex }"
                @click="tabsCilck(v, i)"
              >
                {{ v.title }}
                <i class="el-icon-arrow-down" v-if="v.children"></i>
                <div class="item-children" v-if="v.children">
                  <ul class="item-children-ul">
                    <li
                      class="item-children-grild"
                      v-for="(item, index) in v.children"
                      :key="index"
                      @click.stop="childrenTabsCilck(item)"
                    >
                      {{ item.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    isShadow: {
      type: Boolean,
      default: false,
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabsList: [
        {
          title: '首页',
          link: '/',
        },
        {
          title: '企业概况',
          link: '/company',
        },
        {
          title: '企业新闻',
          link: '/journalism',
        },
        {
          title: '公益阅读',
          link: '/oneX',
        },
        {
          title: '业务服务',
          link: '/Issue',
          children: [
            {
              title: '图书出版发行',
              link: '/Issue',
              icon: '',
            },
            {
              title: '图书装配',
              link: '/Assembly',
              icon: '',
            },
            {
              title: '图书租借',
              link: '/Lease',
              icon: '',
            },
            {
              title: '阅读辅导',
              link: '/course',
              icon: '',
            },
            {
              title: '阅读教育',
              link: '/Education',
              icon: '',
            },
            {
              title: '家庭教育',
              link: '/HomeEducation',
              icon: '',
            },
          ],
        },
        {
          title: '图书商城',
          link: '/bookStore',
        },
        {
          title: '加入我们',
          link: '/joinUs',
          children: [
            {
              title: '招聘信息',
              link: '/joinUs',
              icon: '',
            },
            {
              title: '招募合伙人',
              link: '/Partner',
              icon: '',
            },
            {
              title: '联系方式',
              link: '/contactUs',
              icon: '',
            },
          ],
        },
        {
          title: '合作单位',
          link: '/Cooperation',
        },
      ],
      cunnerIndex: 0,
    }
  },
  methods: {
    tabsCilck(v, i) {
      this.cunnerIndex = i
      this.$router.push({
        path: v.link,
      })
    },
    childrenTabsCilck(v) {
      this.$router.push({
        path: v.link,
      })
    },
  },
  mounted() {
    this.cunnerIndex = this.defaultIndex
  },
  watch: {},
}
</script>

<style scoped lang="scss">
$heaserHight: 78px;

div {
  //outline: 1px solid #4cd964;
  height: 100%;
}
.shadow {
  box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.6);
}
.custom-header {
  width: 100%;

  .app-header {
    .app-header-inner {
      display: flex;

      .app-header-inner-left {
        width: 300px;
        flex-shrink: 0;

        .logo-bg {
          line-height: $heaserHight;
        }

        img {
          width: 140px;
          height: 54px;
        }
      }

      .app-header-inner-right {
        flex-grow: 1;

        .tabs {
          width: 100%;
          display: flex;

          .tabs-item {
            flex-grow: 1;
            text-align: center;
            font-size: $mo-font-size-lg;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: $heaserHight;
            i {
              transition: all 1s ease;
            }
            &:hover {
              .el-icon-arrow-down {
                transform: rotate(-180deg);
              }

              .item-children {
                display: block;
              }
            }
          }
          .item-children {
            position: relative;
            z-index: 9999;
            background: #fff;
            width: 100%;
            display: none;
            &-ul {
              background: #fff;
            }
            &-grild {
              font-size: 14px;
              font-weight: 400;
              color: #4c4c4c;
              line-height: 40px;
              &:hover {
                color: #fd0002;
              }
            }
          }

          .active {
            position: relative;
            font-weight: 600;

            &:after {
              content: '';
              bottom: 0;
              right: 0;
              left: 0;
              margin: 0 auto;
              height: 4px;
              width: 40px;
              background: $mo-main-color;
              display: block;
              position: absolute;
              animation: active 250ms linear 1;
            }
          }
        }
      }
    }
  }
}

@keyframes active {
  0% {
    width: 0;
  }
  60% {
    width: 20px;
  }
  100% {
    width: 40px;
  }
}
</style>
