<template>
  <div class="Cooperation">
    <AppHeader :default-index="4" :isShadow="true"></AppHeader>
    <div class="list">
      <JournalismItem
        v-loading="loading"
        element-loading-text="加载中"
        :detailList="journaDetails"
        :isLink="true"
        :isFamily="1"
      ></JournalismItem>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
          :total="arr.count"
          :page-size="6"
        >
        </el-pagination>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import JournalismItem from '@/components/content/journalism/JournalismItem'
import { readEducationList } from '@/services/api.js'

export default {
  name: 'contactUs',
  components: {
    AppHeader,
    AppFooter,
    JournalismItem,
  },
  data: function () {
    return {
      journalistBanner: [],
      journaDetails: [],
      arr: {},
      loading: true,
    }
  },
  computed: {},
  watch: {},
  created() {
    if (this.journaDetails) {
      this.loading = false
    }
    readEducationList({
      pageNo: '1',
      pageSize: '6',
    }).then((res) => {
      this.arr = res.data
      this.journaDetails = res.data.list
      this.journaDetails.forEach((item) => {
        item.publishTime = item.publishTime.split(' ')[0]
      })
    })
  },
  mounted() {},
  methods: {
    handleCurrentChange(val) {
      this.loading = true
      readEducationList({
        pageNo: val,
        pageSize: '6',
      }).then((res_) => {
        if (res_.code == 200) {
          this.loading = false
          this.journaDetails = res_.data.list
          this.journaDetails.forEach((item) => {
            item.publishTime = item.publishTime.split(' ')[0]
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.Cooperation {
  .list {
    margin-top: 70px;
    min-height: 600px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px;
    ::v-deep.active {
      background: #f52021 !important;
    }
  }
}
</style>