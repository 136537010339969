<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
/* 引入全局样式 */
@import 'assets/scss/common.scss';

#app {
  width: 100%;
  height: 100%;
}
.desc p{
  line-height: 30px !important;
}
</style>
