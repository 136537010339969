<template>
  <div class="journalism">
    <AppHeader :default-index="2"></AppHeader>
    <Banner :list="journalistBanner"></Banner>
    <div class="list">
      <JournalismItem v-loading="loading" element-loading-text="加载中" :detailList="journaDetails"></JournalismItem>
      <div class="pagination">
        <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next" :total="arr.count" :page-size="6">
        </el-pagination>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import JournalismItem from '@/components/content/journalism/JournalismItem'
import { bannerDetail ,pcNoticeList} from '@/services/api.js'

export default {
  name: 'journalism',
  components: {
    AppHeader,
    Banner,
    AppFooter,
    JournalismItem
  },
  mixins: [],
  data: function() {
    return {
      journalistBanner: [],
      journaDetails: [],
      arr:{},
      loading: true
    }
  },
  computed: {},
  watch: {},
  created() {   
    if(this.journaDetails){
      this.loading = false
    }
    let totalNum = 0
    let pageSize = 0
    bannerDetail({
      type:'2'
    }).then( res_ => {
      this.journalistBanner = res_.data
    })
    pcNoticeList({
      pageNo:'1',
      pageSize:'6'
    }).then( res_ => {
      this.arr = res_.data
      this.journaDetails = res_.data.list
      totalNum = res_.data.count
      pageSize = res_.data.pageSize
      this.pageCount = Math.ceil(totalNum / pageSize);
    })
  },
  mounted() {},
  methods: {
    handleCurrentChange(val){
      this.loading = true
      pcNoticeList({
        pageNo:val,
        pageSize:'6'
      }).then( res_ => {
        if(res_.code == 200 ){
          this.loading = false
          this.journaDetails = res_.data.list
        }
        
      })
    }
  }
}
</script>

<style scoped lang="scss">
.journalism {
  .list {
    margin-top: 150px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px;
    ::v-deep.active {
      background: #f52021 !important;
    }
  }
}
</style>
