// 懒加载路由
const Issue = () => import('./Issue.vue')

export default {
  path: '/Issue',
  name: 'Issue',
  component: Issue,
  meta: {
    title: '图书出版发行',
    authLogin: false //是否需要登录
  },
  children: []
}
