<template>
  <div class="drop-down-list">
    <div class="drop-down-list-block">
      <div class="container">
        <div class="drop-down-list-inner">
          <div class="item pointer">
            <el-dropdown @command="gradeCommand">
              <div class="item-inner">
                <div class="item-inner-label">
                  年级
                </div>
                <div class="item-inner-select">
                  {{ defaultGrade }}
                  <i class="el-icon-caret-bottom"></i>
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(v, i) in dictList.grade"
                  :key="i"
                  :command="v.label"
                  @click.native="clickList(v)"
                >{{ v.label }}
                </el-dropdown-item>          
                <!-- <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item>双皮奶</el-dropdown-item>
                <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
                <!--远端数据不显示 bug-->
                <el-dropdown-menu />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="item pointer">
            <el-dropdown @command="courseTypeLCommand">
              <div class="item-inner">
                <div class="item-inner-label">
                  课程
                </div>
                <div class="item-inner-select">
                  {{ defaultCourseType }}
                  <i class="el-icon-caret-bottom"></i>
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(v, i) in dictList.courseType"
                  :key="i"
                  :command="v.label"
                  @click.native="clickCourseType(v)"
                >{{ v.label }}</el-dropdown-item>
                <!-- <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item>双皮奶</el-dropdown-item>
                <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
                <!--远端数据不显示 bug-->
                <el-dropdown-menu />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="item pointer">
            <el-dropdown @command="teacherCommand">
              <div class="item-inner">
                <div class="item-inner-label">
                  教师
                </div>
                <div class="item-inner-select">
                  {{ defaultTeacher }}
                  <i class="el-icon-caret-bottom"></i>
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(v, i) in dictList.teacher"
                  :key="i"
                  :command="v.label"
                  @click.native="clickTeacher(v)"
                >{{ v.label }}</el-dropdown-item>
                <!-- <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item>双皮奶</el-dropdown-item>
                <el-dropdown-item>蚵仔煎</el-dropdown-item> -->
                <!--远端数据不显示 bug-->
                <el-dropdown-menu />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="drop-down-list-in">
      <div class="drop-down-list-in- container">
        {{ grade }} >> {{ courseType }} >> {{ teacher }}
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dictList: Object
  },
  name: 'DropDownList',
  data() {
    return {
      // 默认选择
      defaultGrade: '',
      defaultCourseType: '',
      defaultTeacher: '',
      grade: '',
      courseType: '',
      teacher: ''
    }
  },
  mounted() {},
  methods: {
    clickList(v) {
      this.$emit('listData', v)
    },
    clickCourseType(v) {
      this.$emit('courseType', v)
    },
    clickTeacher(v) {
      this.$emit('teacher', v)
    },
    gradeCommand(command) {
      this.grade = command
      this.defaultGrade = command
    },
    courseTypeLCommand(command) {
      this.courseType = command
      this.defaultCourseType = command
    },
    teacherCommand(command) {
      this.teacher = command
      this.defaultTeacher = command
    }
  },
  watch: {
    dictList() {
      let arrGrade = []
      let arrCourseType = []
      let arrTeacher = []
      // 年纪
      this.dictList.grade.forEach(item => {
        arrGrade.push(item.label)
      })
      this.defaultGrade = arrGrade[0]
      this.grade = arrGrade[0]
      // 课程
      this.dictList.courseType.forEach(item => {
        arrCourseType.push(item.label)
      })
      this.defaultCourseType = arrCourseType[0]
      this.courseType = arrCourseType[0]
      // 教师
      this.dictList.teacher.forEach(item => {
        arrTeacher.push(item.label)
      })
      this.defaultTeacher = arrTeacher[0]
      this.teacher = arrTeacher[0]
    }
  }
}
</script>

<style scoped lang="scss">
.drop-down-list {
  min-width: $mo-main-width-base;
  padding-right: 88px;
  .drop-down-list-block {
    background: rgba(238, 245, 255, 1);
  }
  .drop-down-list-inner {
    display: flex;
    justify-content: space-around;
    background: rgba(238, 245, 255, 1);
    padding: $mo-spacing-col-base 0;
    .item-inner {
      display: flex;
      align-items: center;
      font-size: $mo-font-size-lg;
      .item-inner-select {
        padding: 0 20px;
        height: 46px;
        background: #ffffff;
        border: 1px solid #2445f3;
        border-radius: 4px;
        text-align: center;
        line-height: 46px;
        font-size: $mo-font-size-lg;
        margin-left: $mo-spacing-col-sm;
        color: #294bfe;
        .el-icon-caret-bottom {
          transition: all 500ms ease;
        }
        &:hover {
          .el-icon-caret-bottom {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}
.drop-down-list-in- {
  line-height: 60px;
  font-size: $mo-font-size-base;
  font-weight: 600;
  color: #222222;
  padding-left: 40px;
}
</style>
