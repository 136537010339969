<template>
  <div class="Cooperation">
    <AppHeader :default-index="4" :isShadow="true"></AppHeader>
    <div class="synopsis-item">
      <div class="synopsis-item-inner container">
        <div class="synopsis-item-inner-block">
          <div class="left">
            <div class="title">
              <TitleItem :title="info.china" desc="Book assembly"></TitleItem>
            </div>
            <div class="desc" v-html="info.content">
             
            </div>
          </div>
          <div class="img">
            <img :src="imageUrl + info.enclosure" alt="" />
            <div class="icon"></div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import TitleItem from '@/components/content/company/TitleItem'
import { booksAssemblyInfo } from '@/services/api.js'

export default {
  name: 'contactUs',
  components: {
    AppHeader,
    AppFooter,
    TitleItem,
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      contactUsBanner: [],
      index: 0,
      screenWidth: document.body.clientWidth,
      info: {},
      imageUrl: this.$http.imageUrl
    }
  },
  created(){
      booksAssemblyInfo().then( res => {
          console.log(res)
          this.info = res.data
      })
  },
  computed: {},
  watch: {},
    mounted() {
    const that = this
    window.onresize = () => {
      return (that.screenWidth = document.body.clientWidth)
    }
  },
  methods: {
    IntroduceChange(e) {
      this.index = e
    },
    PartnerFormChange(e) {
      this.index = e
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.middle {
  width: 1200px;
  margin: 98px auto;
  .title {
    font-size: 26px;
    font-weight: bold;
    color: #222222;
  }
  .title + p {
    margin-top: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #4c4c4c;
    line-height: 32px;
  }
}

.synopsis-item {
  min-height: 700px;
  padding: 70px 0 200px 0;
  .synopsis-item-inner-block {
    display: flex;
    position: relative;
    .img {
      flex-shrink: 0;
      width: 513px;
      height: 331px;
      position: relative;
      margin-left: $mo-spacing-col-lg;
      .icon {
        position: absolute;
        right: -10px;
        bottom: -10px;
        width: 99px;
        height: 78px;
        border-left: 99px solid transparent;
        border-bottom: 78px solid #f52021;
        box-shadow: 0px 10px 20px 0px rgba(245, 32, 33, 0.2);
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      .desc {
        padding-top: $mo-spacing-row-base;
        flex-grow: 1;
        color: #4c4c4c;
        line-height: 30px;
      }
    }
    .icon-img {
      width: 10px;
      height: 384px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15%;
    }
  }
}
</style>
