<template>
  <div class="synopsis-item">
    <div class="synopsis-item-inner container">
      <div class="synopsis-item-inner-block">
        <div class="left">
          <div class="title">
            <TitleItem title="企业简介" desc="company profiles"></TitleItem>
          </div>
          <div class="desc">
            <p>
              太原睿图文化传播有限公司成立于2009年4月，注册资金500万元。主要从事图书、报刊、杂志的编辑与销售；企业形象策划、企业管理咨询；会议会展服务；市场营销策划与产品推广服务。
            </p>
            <p>
              公司是山西新华连锁公司的重要合作单位。连续几年都荣获全国十佳供应商。几年以来，太原睿图所采购的图书基本都以主配的形式辐射至102家新华基层书店。目前公司正在在全省大力推广“山西省少年儿童1+X公益阅读计划”。并与省教科院共同推出了山西省十三五规划课题，在全省建立三百家实验基地学校。
            </p>
            <p>
              诚信、规范、包容、共赢的是公司成立以来始终秉承的经营理念。传承经典、推广阅读，提高少年儿童人文素养和文化积淀，为少年儿童的人生成长做好打好基础，进而提高全民族的文化素质将是睿图矢志不变的追求目标。
            </p>
          </div>
        </div>
        <div class="img">
          <img src="~images/compary/icon_23.png" alt="" />
          <div class="icon"></div>
        </div>
        <div class="icon-img" v-if="screenWidth > 1350">
          <img src="~images/compary/icon_29.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleItem from '@/components/content/company/TitleItem'
export default {
  components: {
    TitleItem
  },
  name: 'SynopsisItem',
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (that.screenWidth = document.body.clientWidth)
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped lang="scss">
.synopsis-item {
  padding: 40px 0;
  .synopsis-item-inner-block {
    display: flex;
    position: relative;
    .img {
      flex-shrink: 0;
      width: 513px;
      height: 331px;
      position: relative;
      margin-left: $mo-spacing-col-lg;
      .icon {
        position: absolute;
        right: -10px;
        bottom: -10px;
        width: 99px;
        height: 78px;
        border-left: 99px solid transparent;
        border-bottom: 78px solid #f52021;
        box-shadow: 0px 10px 20px 0px rgba(245, 32, 33, 0.2);
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      .desc {
        padding-top: $mo-spacing-row-base;
        flex-grow: 1;
        color: #4c4c4c;
        line-height: 30px;
      }
    }
    .icon-img {
      width: 10px;
      height: 384px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15%;
    }
  }
}
</style>
