<template>
  <div class="oneX">
    <AppHeader :default-index="3"></AppHeader>
    <Banner :list="oneXBanner"></Banner>
    <Block title="睿图公益阅读介绍" desc="Ruitu Introduction">
      <p class="desc">睿图公益阅读项目是在教科院教研支持和基金会倡议全社会支持、参与全民阅读的情况下，由太原睿图文化发起和具体执行操作的项目，此项目主要为我省小学完善阅览室建设，提供教研服务，开展阅读活动等</p>
      <IntroductionItem :xImg="xImg" :xWelfare="xWelfare"></IntroductionItem>
    </Block>
    <Block title="相关资讯" desc="relevant information">
      <div class="list container">
        <InformationItem
          v-for="(v, i) in xNotice"
          :key="i"
          :item="v"
        ></InformationItem>
      </div>
    </Block>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next"
        :total="xNoticeArr.count"
        :page-size="6"
      >
      </el-pagination>
    </div>
    <div class="butt">
      <div class="img">
        <el-image
          fit="convr"
          :src="require('images/OneX/icon_59.png')"
        ></el-image>
      </div>
      <div class="butt-inner">
        山西省少年儿童1+X公益阅读项目网站链接：<a
          href="https://www.rtprpc.com"
          target="_blank"
          >https://www.rtprpc.com</a
        >
      </div>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import { AppHeader, AppFooter } from 'content/App/app'
import Banner from '@/components/content/Banner'
import Block from '@/components/content/home/Block'
import IntroductionItem from '@/components/content/OneX/IntroductionItem'
import InformationItem from '@/components/content/OneX/InformationItem'
import { bannerDetail, bannerxList, pcNoticeList } from '@/services/api.js'
export default {
  name: 'oneX',
  components: {
    AppHeader,
    Banner,
    AppFooter,
    Block,
    IntroductionItem,
    InformationItem
  },
  mixins: [],
  props: {},
  data: function() {
    return {
      oneXBanner: [],
      xImg: [],
      xNotice: [],
      xNoticeArr: [],
      xWelfare: []
    }
  },
  computed: {},
  watch: {},
  created() {
    bannerDetail({
      type: '4'
    }).then(res_ => {
      this.oneXBanner = res_.data
    })
    // 1x 图
    bannerxList().then(res_ => {
      this.xImg = res_.data
    })
    // 咨询
    pcNoticeList({
      pageNo: '1',
      pageSize: '4'
    }).then(res_ => {
      this.xNoticeArr = res_.data
      this.xNotice = res_.data.list
      this.xWelfare = res_.data.list
    })
  },
  mounted() {},
  methods: {
    handleCurrentChange(val) {
      pcNoticeList({
        pageNo: val,
        pageSize: '6'
      }).then(res_ => {
        this.xNotice = res_.data.list
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list {
  padding-bottom: 100px;
}

.img {
  min-width: $mo-main-width-base;
  width: 100%;
  height: 160px;

  ::v-deep.el-image {
    width: 100%;
    height: 100%;
  }
}

.butt {
  position: relative;
  min-width: $mo-main-width-base;

  .butt-inner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #fff;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
  ::v-deep.active {
    background: #f52021 !important;
  }
}
.desc {
  text-align: center;
  width: 50%;
  margin: 0 auto 20px;
  font-size: 14px;
  font-weight: 400;
  color: #4c4c4c;
  line-height: 35px;
}
</style>
